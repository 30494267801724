<template>
  <b-overlay
    :show="showOver"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div>
      <div class="card">
        <div class="nav-panel anan-tabs anan-tabs-line anan-tabs-normal anan-tabs-top">
          <div class="anan-tabs__nav">
            <div
              class="anan-tabs__nav-warp px-2 table-container"
              style="margin-top: 5px;"
            >
              <div
                class="anan-tabs__nav-tabs"
                style="transform: translateX(0px)"
              >
                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'all' }"
                  style="white-space: normal"
                  @click="GetOrderAll(status = 0, delivery, search), isActive = 'all'"
                >
                  <span> {{ $t('all') }} </span>

                  <span class="badge badge-pill badge-warning badges">
                    {{ total }}
                  </span>
                </div>

                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'waiting' }"
                  style="white-space: normal"
                  @click="GetOrderAll(status = 1, delivery, search), isActive = 'waiting'"
                >
                  <span> {{ $t('pendingReview') }} </span>
                  <span
                    v-if="waitingforcheck"
                    class="badge badge-pill badge-warning badges"
                  >
                    {{ waitingforcheck }}
                  </span>
                </div>

                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'waiting_pay' }"
                  style="white-space: normal"
                  @click="GetOrderAll(status = 2, delivery, search), isActive = 'waiting_pay'"
                >
                  <span> {{ $t('awaitingPayment') }} </span>
                  <span
                    v-if="waitingforpayment"
                    class="badge badge-pill badge-warning badges"
                  >
                    {{ waitingforpayment }}
                  </span>
                </div>

                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'paid' }"
                  style="white-space: normal"
                  @click="GetOrderAll(status = 3, delivery, search), isActive = 'paid'"
                >
                  <span> {{ $t('paidAmount') }} </span>
                  <span
                    v-if="paid"
                    class="badge badge-pill badge-warning badges"
                  >
                    {{ paid }}
                  </span>
                </div>

                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'ord_success' }"
                  style="white-space: normal"
                  @click="GetOrderAll(status = 4, delivery, search), isActive = 'ord_success'"
                >
                  <span> {{ $t('orderSuccess') }} </span>
                  <span
                    v-if="OrderSuccess"
                    class="badge badge-pill badge-warning badges"
                  >
                    {{ OrderSuccess }}
                  </span>
                </div>

                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'track' }"
                  style="white-space: normal"
                  @click="GetOrderAll(status = 5, delivery, search), isActive = 'track'"
                >
                  <span> {{ $t('fullTrack') }} </span>
                  <span
                    v-if="completetrack"
                    class="badge badge-pill badge-warning badges"
                  >
                    {{ completetrack }}
                  </span>
                </div>

                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'warehouse' }"
                  style="white-space: normal"
                  @click="GetOrderAll(status = 6, delivery, search), isActive = 'warehouse'"
                >
                  <span> {{ $t('completeWarehouseChina') }} </span>
                  <span
                    v-if="completewarehouse"
                    class="badge badge-pill badge-warning badges"
                  >
                    {{ completewarehouse }}
                  </span>
                </div>

                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'chinesewarehouse' }"
                  style="white-space: normal"
                  @click="GetOrderAll(status = 8, delivery, search), isActive = 'chinesewarehouse'"
                >
                  <span> {{ $t('completeWarehouseChinaExit') }} </span>
                  <span
                    v-if="chinesewarehouse"
                    class="badge badge-pill badge-warning badges"
                  >
                    {{ chinesewarehouse }}
                  </span>
                </div>

                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'thaiwarehouse' }"
                  style="white-space: normal"
                  @click="GetOrderAll(status = 9, delivery, search), isActive = 'thaiwarehouse'"
                >
                  <span> {{ $t('completeWarehouseThailand') }} </span>
                  <span
                    v-if="thaiwarehouse"
                    class="badge badge-pill badge-warning badges"
                  >
                    {{ thaiwarehouse }}
                  </span>
                </div>

                 <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'thaiwarehouseExit' }"
                  style="white-space: normal"
                  @click="GetOrderAll(status = 10, delivery, search), isActive = 'thaiwarehouseExit'"
                >
                  <span> {{ $t('leftWarehouseThai') }} </span>
                  <span
                    v-if="thaiwarehouseExit"
                    class="badge badge-pill badge-warning badges"
                  >
                    {{ thaiwarehouseExit }}
                  </span>
                </div>

                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'cancel' }"
                  style="white-space: normal"
                  @click="GetOrderAll(status = 7, delivery, search), isActive = 'cancel'"
                >
                  <span> {{ $t('cancel') }} </span>
                  <span
                    v-if="cancel"
                    class="badge badge-pill badge-warning badges"
                  >
                    {{ cancel }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-lg-flex justify-content-between align-items-center px-2">
          <h3 class="font-weight-bolder">
            {{ $t('ordering') }}
          </h3>

          <div class="d-flex align-items-center">
            <div class="anan-input__inner anan-input__inner--normal">
              <input
                v-model="search"
                type="text"
                :placeholder="this.$t('search')"
                class="anan-input__input"
                @change="GetOrderAll(status, selectedRadio, search)"
              >
              <div class="anan-input__suffix">
                <i class="anan-input__clear-btn anan-icon" />
                <i class="anan-input__suffix-icon anan-icon">
                  <i class="fal fa-search" />
                </i>
              </div>
            </div>

            <b-form-select
              v-model="selectedRadio"
              :options="optionsRadio"
              class="w-50 ml-1"
              size="sm"
            />
            <!-- @change="GetOrderAll(status, selectedRadio, search)" -->
            <!-- <b-form-radio-group
              id="btn-radios-1"
              v-model="selectedRadio"
              style="margin-left: 10px; margin-top: 3px;"
              button-variant="outline-primary"
              :options="optionsRadio"
              size="sm"
              buttons
              name="radios-btn-default"

            /> -->
            <!-- @change="GetOrderAll(status, selectedRadio, search)" -->
          </div>
        </div>

        <div class="p-2 table-container">
          <table class="table table-width-1">
            <colgroup>
              <col width="10%">
              <col width="10%">
              <col width="5%">
              <col width="30%">
              <col width="20%">
              <col width="10%">
              <col width="10%">
            </colgroup>
            <thead>
              <tr class="text-center">
                <th>{{ $t('number') }}</th>
                <th>{{ $t('customer') }}</th>
                <th>{{ $t('product') }}</th>
                <th>{{ $t('mnemonicPhraseAdmin') }}</th>
                <th>...</th>
                <th>{{ $t('deliveryBy') }}</th>
                <th>{{ $t('status') }}</th>
                <th>{{ $t('manage') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in items"
                :key="index"
                :style="`background: ${item.lock_order && item.lock_by ? '#FFEEEE' : (item.status === 2 ? '#dcedff' : '#F5F5F5')} !important`"
              >
                <td>

                  <b-link
                    :to="{ name: 'admin-order-detail', params: { id: item._id } }"
                    target="_blank"
                  >
                    ORD-{{ item.orderId_text }}
                  </b-link> <br>
                  <small>{{ time(item.updated_at) }}</small>
                </td>

                <td class="text-center">
                  <b-link :to="{ name: 'admin-employee-edit', params: { id: item.uid } }">
                    {{ item.username }}
                  </b-link>
                </td>

                <td>
                  <!-- {{ item.shops[0].products[0] }}
                  {{ index }} -->
                  <!-- <div class="img-pdx">
                    <img
                      :src="item.shops[index].products[index].picture"
                      alt="..."
                      class="img-fluid rounded cursor-pointer"
                      @click="showModal(item.shops[index].products[index].picture)"
                    >
                  </div> -->

                  <div
                    v-if="item.shops.length > 0 && item.shops[0].products[0]"
                    class="img-pdx"
                  >
                    <img
                      :src="item.shops[0].products[0].picture"
                      alt="..."
                      class="img-fluid rounded cursor-pointer"
                      @click="showModal(item.shops[0].products[0].picture)"
                    >
                  </div>
                </td>

                <td>
                  <textarea
                    ref="textarea"
                    v-model="item.note_admin"
                    rows="1"
                    class="ant-input ghost ant-input-sm sss"
                    @input="resize"
                    @change="updateKeyword(item._id, item.note_admin)"
                  />
                </td>

                <td>
                  <p class="my-0">
                    {{ $t('shopCount') }} : {{ Commas(item.shops.length) }}
                  </p>
                  <p class="my-0">
                    {{ $t('noParcelCode') }} : {{ Commas(item.shops.reduce((acc, shop) => acc + (!shop.show && shop.have_postnumber ? 0 : shop.show ? 0 : 1), 0)) }}
                  </p>
                  <p class="my-0">
                    {{ $t('notInWarehouse') }} : {{ Commas(item.shops.reduce((acc, shop) => acc +(!shop.show && shop.come_chinesewarehouse ? 0 : shop.show ? 0 : 1), 0)) }}
                  </p>
                  <hr>
                  <p class="font-weight-bolder">
                    {{ $t('total') }} : {{ Commas(item.price_total ? item.price_total : 0) }} ฿
                  </p>
                </td>

                <td class="text-center">
                  <i
                    v-if="item.delivery_type == 1"
                    class="fas fa-shipping-fast"
                  >
                    <p class="font-weight-bolder">
                      {{ $t('truck') }}
                    </p>
                  </i>

                  <i
                    v-if="item.delivery_type == 2"
                    class="fas fa-ship text-info"
                  >
                    <p class="font-weight-bolder">
                      {{ $t('ship') }}
                    </p>
                  </i>
                </td>

                <td class="text-center">
                  <b-badge
                    v-if="item.status === 1"
                    variant="light-warning"
                  >
                    {{ $t('pendingReview') }}

                  </b-badge>
                  <p v-if="item.status === 1 && item.lock_order">
                    {{ item.lock_by }}
                  </p>

                  <b-badge
                    v-if="item.status === 2"
                    variant="light-danger"
                  >
                    {{ $t('awaitingPayment') }}
                  </b-badge>

                  <b-badge
                    v-if="item.status === 3"
                    variant="light-info"
                  >
                    {{ $t('paidAmount') }}
                  </b-badge>

                  <b-badge
                    v-if="item.status === 4"
                    variant="light-success"
                  >
                    {{ $t('orderSuccess') }}
                  </b-badge>

                  <b-badge
                    v-if="item.status === 5"
                    variant="light-info"
                  >
                    {{ $t('fullTrack') }}
                  </b-badge>

                  <b-badge
                    v-if="item.status === 6"
                    variant="light-info"
                  >
                    {{ $t('completeWarehouseChina') }}
                  </b-badge>

                  <b-badge
                    v-if="item.status === 7"
                    variant="light-danger"
                  >
                    {{ $t('cancel') }}
                  </b-badge>

                  <b-badge
                    v-if="item.status === 8"
                    variant="light-warning"
                  >
                    {{ $t('completeWarehouseChinaExit') }}
                  </b-badge>

                  <b-badge
                    v-if="item.status === 9"
                    variant="light-warning"
                  >
                    {{ $t('completeWarehouseThailand') }}
                  </b-badge>
                  <b-badge
                    v-if="item.status === 10"
                    variant="light-warning"
                  >
                    {{ $t('leftWarehouseThai') }}
                  </b-badge>
                </td>

                <td class="text-center">
                  <b-link
                    :to="{ name: 'admin-order-detail', params: { id: item._id } }"
                    target="_blank"
                  >
                    <i class="fas fa-edit mr5" />
                  </b-link>

                  <feather-icon
                    v-if="item.status === 1 && item.lock_order"
                    icon="LockIcon"
                    class="text-danger"
                    style="cursor: pointer;"
                    size="16"
                    @click="item.lock_by === UserData.username ? Lockitem(item._id, false) : null"
                  />
                  <!-- @click="Lockitem(item._id, false, item.lock_by)" -->
                  <feather-icon
                    v-if="item.status === 1 && !item.lock_order"
                    style="cursor: pointer;"
                    icon="UnlockIcon"
                    class="text-primary"
                    size="16"
                    @click="Lockitem(item._id, true)"
                  />
                  <!-- <b-link :to="{name : 'admin-order-detail-view', params: {id: item._id}}" target="_blank"> -->
                  <!-- <i class="fas fa-eye" /> -->

                  <!-- </b-link> -->
                </td>
              </tr>

              <tr v-if="items.length === 0">
                <td
                  colspan="8"
                  class="text-center"
                >
                  <img
                    src="@/assets/images/anan-img/empty/empty.png"
                    alt="empty"
                    width="180px"
                  >
                  <p>No Data</p>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="demo-spacing-0 d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @input="GetOrderAll(status, delivery, search)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>

      </div>
      <!-- modal -->
      <b-modal
        ref="my-modal"
        hide-footer
        :title="$t('key-34')"
      >
        <img
          :src="pic"
          alt="..."
          class="img-fluid rounded"
        >

        <div class="text-center">
          <button
            type="button"
            class="btn btn-primary mt-2"
            @click="downloadImg"
          >
            Download
          </button>
        </div>
      </b-modal>
    </div>

    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
    />
  </b-overlay>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'
import {
  BPagination, BFormRadioGroup, BLink, BBadge, BModal, BOverlay, BFormSelect,
  // BIconController,
} from 'bootstrap-vue'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  components: {
    BPagination,
    BFormRadioGroup,
    BLink,
    BBadge,
    BModal,
    BOverlay,
    VueImageLightboxCarousel,
    BFormSelect,
    // BIconController,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      search: null,
      isActive: 'all',
      selectedRadio: null,
      optionsRadio: [
        { text: this.$t('all'), value: null },
        { text: this.$t('orderNumber'), value: 1 },
        { text: this.$t('customerCode'), value: 2 },
        { text: this.$t('หมายเลขใบสั่งซื้อ'), value: 3 },
        // หมายเลขใบสั่งซื้อ
      ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      items: [],
      pic: null,
      showOver: false,
      total: 0,
      waitingforcheck: 0,
      waitingforpayment: 0,
      paid: 0,
      chinesewarehouse: 0,
      thaiwarehouse: 0,
      thaiwarehouseExit: 0,
      OrderSuccess: 0,
      completetrack: 0,
      completewarehouse: 0,
      cancel: 0,
      status: 0,
      delivery: null,
      orderId_text: null,
      UserData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  watch: {
    '$route.query.querykey': {
      immediate: true,
      handler() {
        this.handleQueryChange()
      },
    },
  },
  mounted() {
    this.handleQueryChange()
  },
  methods: {
    handleQueryChange() {
      const { querykey } = this.$route.query
      if (querykey) {
        if (Number(querykey) === 1) {
          this.isActive = 'waiting'
          this.GetOrderAll(this.status = 1, this.delivery, this.search)
        } else if (Number(querykey) === 2) {
          this.isActive = 'paid'
          this.GetOrderAll(this.status = 3, this.delivery, this.search)
        }
      } else {
        this.GetOrderAll(this.status, this.delivery, this.search)
      }
    },
    time(data) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    // eslint-disable-next-line camelcase
    GetNotify(type, delivery, orderId_text) {
      const params = {
        type,
        delivery,
        orderId_text,
      }
      this.$http
        .get('/order/Notify', { params })
        .then(response => {
          // console.log(response.data)
          this.total = response.data.total
          this.waitingforcheck = response.data.waitingforcheck
          this.waitingforpayment = response.data.waitingforpayment
          this.paid = response.data.paid
          this.OrderSuccess = response.data.OrderSuccess
          this.completetrack = response.data.completetrack
          this.chinesewarehouse = response.data.chinesewarehouse
          this.thaiwarehouse = response.data.thaiwarehouse
          this.thaiwarehouseExit = response.data.thaiwarehouseExit
          this.completewarehouse = response.data.completewarehouse
          this.cancel = response.data.cancel
        })
        .catch(error => console.log(error))
    },
    Lockitem(ID, check) {
      this.$swal({
        title: check ? 'ล็อคออเดอร์หรือไม่ ?' : 'ปลดล็อคออเดอร์หรือไม่ ?',
        // text: `หรือไม่ ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          const obj = {
            ID,
            check,
          }
          this.$http.post('order/LockOrder', obj)
            .then(() => {
              this.GetOrderAll()
              this.Success(check ? 'ล็อคออเดอร์สำเร็จ' : 'ปลดล็อคออเดอร์สำเร็จ')
            })
            .catch(err => {
              console.log(err)
              this.Alert(false, 'เกิดข้อผิดพลาด', check ? 'ล็อคออเดอร์ได้' : 'ปลดล็อคออเดอร์ได้')
            })
        }
      })
    },
    Alert(status, title, mes) {
      this.$swal({
        icon: `${status ? 'success' : 'error'}`,
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    // eslint-disable-next-line camelcase
    GetOrderAll(type, delivery, orderId_text) {
      this.showOver = true
      this.GetNotify(type, delivery, orderId_text)
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        type,
        delivery,
        orderId_text,
      }
      this.$http
        .get('/order/orderAll', { params })
        .then(response => {
          // console.log(response.data)
          this.items = response.data.data
          // this.totalRows = this.items.length
          this.totalRows = response.data.total
          this.showOver = false
        })
        .catch(error => console.log(error))
    },
    resize(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = 'auto'
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = `${event.target.scrollHeight}px`
    },
    updateKeyword(id, note) {
      // console.log('Item ID:', id)
      // console.log('note:', note)
      const params = {
        id,
        note,
      }
      // console.log(params)
      this.$http
        .post('/order/updatenote/admin', params)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.Success(this.$t('key-260'))
        })
        .catch(error => console.log(error))
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    showModal(picture) {
      // this.pic = data
      // this.$refs['my-modal'].show()
      this.showLightbox = true
      const img = { path: picture }
      this.images.push(img)
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    downloadImg() {
      const imageURL = '@/assets/images/anan-img/example/1.jpg'
      const link = document.createElement('a')
      link.href = imageURL
      link.download = 'image.jpg'
      link.click()
    },
  },
}
</script>

<style lang="scss" scoped></style>
