<template>
  <div>
    <div class="card">
      <div class="d-lg-flex justify-content-between align-items-center px-2 mt-2">
        <h3 class="font-weight-bolder">
          {{ $t('receiveInThailand') }}
        </h3>

        <div class="d-flex">
          <div class="income-order-search order-search w-100">
            <div class="anan-input search-input">
              <div class="anan-input__inner anan-input__inner--normal pl-75 pt-25 pb-25 pr-25">
                <input v-model="search_val" type="text" :placeholder="$t('search')" class="anan-input__input"
                  @keyup.enter="getData()">
                <div class="anan-input__suffix">
                  <button type="button" class="anan-button anan-button--normal anan-button--primary" @click="getData">
                    <i class="fal fa-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="px-2 pt-1">
        <button type="button" class="anan-button--xl-large anan-button anan-button--primary anan-button--normal"
          @click="AddData">
          <span>+ {{ $t('addProductToThailand') }} </span>
        </button>
      </div>
      <b-overlay :show="showOver" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              กรุณารอสักครู่...
            </p>
          </div>
        </template>
        <div class="pl-2 pr-2 pt-1">
          <b-table striped hover responsive :per-page="perPage" :items="items" :fields="fields" :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
            :filter-included-fields="filterOn" show-empty>
            <template #empty>
              <div class="text-center">
                <img src="@/assets/images/anan-img/empty/empty.png" alt="empty" width="180px">
                <p>No Data</p>
              </div>
            </template>

            <template #cell(number)="data">
              <b-link :to="{name: 'admin-user-type-edit', params: {id: data.item.id}}">
                GUSR-{{ items.length - (perPage * (currentPage - 1) + (data.index + 1)) + 1 }}
              </b-link>
            </template>
            <template #cell(wh_lot)="data">
              <router-link v-for="(item,index) in data.item.wh_lot" :key="item._id"
                :to="{name : 'admin-warehouse-edit' , params: { id: item.lot_name }}">
                {{ item.lot_name }} {{ (data.item.wh_lot.length - 1) > index ? ',':'' }}
              </router-link>
            </template>
            <!-- <template #cell(index)="data">
                    {{ perPage * (currentPage - 1) + (data.index + 1) }}
                  </template> -->
            <template #cell(status)="data">
              <b-badge v-if="data.item.quantity_cn === 0 && data.item.quantity_th === 0" variant="light-dark">
                {{ $t('key-11') }}
              </b-badge>
              <b-badge v-else-if="data.item.quantity_cn === data.item.quantity_th " variant="light-success">
                {{ $t('completeWarehouseThailand') }}
              </b-badge>
              <b-badge v-else variant="light-danger">
                {{ $t('incompleteWarehouseThailand') }}
              </b-badge>
            </template>
            <template #cell(actions)="data">
              <feather-icon icon="Edit3Icon" class="mr-1 text-primary cursor-pointer" @click="EditType(data.item)" />
              <feather-icon icon="RepeatIcon" class="mr-1 text-primary cursor-pointer" @click="ResetData(data.item)" />
              <!-- <feather-icon icon="TrashIcon" class="mr-1 text-primary cursor-pointer" @click="DeleteData(data.item)" /> -->
            </template>

            <template #cell(come_thaiwarehouse)="data">
              {{ data.item.come_thaiwarehouse ? time(data.item.come_thaiwarehouse): '-' }}
            </template>


          </b-table>

          <div class="demo-spacing-0 d-flex justify-content-end mb-2">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
              prev-class="prev-item" next-class="next-item" class="mb-0" @input="getData">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import {
  BPagination, BTable, BBadge, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment-timezone'

export default {
  components: {
    BTable,
    BPagination,
    BBadge,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isActive: 'all',
      selected: null,
      amount: 0,
      detail: '',
      type: null,
      customerCode: '',
      prefix: '',
      prefixNum: '',

      options: [
        { value: null, text: 'ทั้งหมด' },
        { value: 1, text: 'รถ' },
        { value: 2, text: 'เรือ' },
      ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 5,
      totalRows: 1,
      items: [],
      search_val: '',
      showOver: false,
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'wh_name', label: this.$t('orderNumber'), thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'remark', label: this.$t('remarks'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'wh_lot', label: this.$t('key-1'), sortable: false, thStyle: { width: '25%' }, thClass: 'text-center',
        },
        {
          key: 'created_at', label: this.$t('transactionDate'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center', formatter: value => moment(value).format('DD/MM/YYYY HH:mm:ss'),
        },
        {
          key: 'come_thaiwarehouse', label: this.$t('key-26'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center', 
        },
        {
          key: 'quantity_cn', label: this.$t('amountExitChina'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center', formatter: value => this.Commas(value),
        },
        {
          key: 'quantity_th', label: this.$t('amountEnterThailand'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center', formatter: value => this.Commas(value),
        },
        {
          key: 'status', label: this.$t('status'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center',
        },
        {
          key: 'last_edit_name', label: this.$t('lastEditedBy'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'actions', label: this.$t('manage'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    time(data) {
      return moment(data).format('DD/MM/YYYY HH:mm:ss')
    },
    submitAddNew() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const params = {
            customerCode: this.customerCode,
            type: this.type,
            detail: this.detail,
            amount: this.amount,
            prefix: this.prefix,
            prefixNum: this.prefixNum,
          }
          this.$http.post('groupbyuser/list/create', params).then(() => {
            this.getData(this.isActive)
            this.$bvToast.toast('เพิ่มข้อมูลสำเร็จ', {
              title: 'สำเร็จ',
              variant: 'success',
              solid: true,
              autoHideDelay: 1500,
            })
          })
          this.$bvModal.hide('modal-add-new')
        }
      })
    },
    ResetData(data) {
      const params = {
        ID: data._id
      }
      this.$http.get('/warehouseTH/fix_quantity', { params })
        .then(response => {
          if (response.data) {
            this.Success(this.$t('key-215'))
            this.getData()
          }
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    showAddNew() {
      this.$bvModal.show('modal-add-new')
    },
    async getData() {
      this.showOver = true
      try {
        const params = {
          page: this.currentPage,
          perPage: this.perPage,
          search_val: this.search_val,
        }
        const { data: res } = await this.$http.get('/warehouseTH/showall', { params })
        this.items = res.data
        this.totalRows = res.total
        this.showOver = false
      } catch (e) {
        console.log(e)
        this.showOver = false
      }
    },
    EditType(data) {
      // eslint-disable-next-line no-underscore-dangle
      this.$router.push({ name: 'admin-warehouse-thai-edit', params: { id: data._id } })
    },
    AddData() {
      this.$swal({
        title: 'ต้องการเพิ่มรายการ',
        text: 'เพิ่มรายการรับสินค้าเข้าไทย',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.post('warehouseTH/store').then(response => {
            console.log(response)
            // eslint-disable-next-line no-underscore-dangle
            this.$router.push({ name: 'admin-warehouse-thai-edit', params: { id: response.data.data._id } })
          })
        }
      })
    },
    // DeleteData(data) {
    //   this.$bvModal.msgBoxConfirm('คุณต้องการลบข้อมูลใช่หรือไม่?', {
    //     title: 'ยืนยันการลบข้อมูล',
    //     size: 'sm',
    //     buttonSize: 'sm',
    //     okVariant: 'danger',
    //     okTitle: 'YES',
    //     cancelTitle: 'NO',
    //     footerClass: 'p-2',
    //     hideHeaderClose: false,
    //     centered: true,
    //   })
    //     .then(value => {
    //       if (value) {
    //         const params = {
    //           id: data.id,
    //         }
    //         this.$http.post('groupbyuser/list/delete', params).then(() => {
    //           this.getData(this.isActive)
    //           this.$bvToast.toast('ลบข้อมูลสำเร็จ', {
    //             title: 'สำเร็จ',
    //             variant: 'success',
    //             solid: true,
    //             autoHideDelay: 1500,
    //           })
    //         })
    //       }
    //     })
    //     .catch(err => {
    //       console.log(err)
    //     })
    // },
  },
}
</script>

                <style lang="scss" scoped></style>
