<template>
  <div>
    <header class="h_header">
      <div class="h_top v-container d-flex align-items-center">
        <div class="h_logo">
          <img
            src="@/assets/images/logo/logo-p2-cargo.webp"
            alt="logo"
            title="anan-cargo-logo"
          >
        </div>
      </div>
    </header>

    <main>
      <div class="bg_content">
        <div class="bg_content2 p-md-5 p-1">
          <div class="row">
            <div class="col-md-6">
              <div class="text-center text-white h-100 mt-10">
                <div>
                  <b-img
                    src="@/assets/images/logo/logo-login.webp"
                    alt="logo"
                    title="anan-cargo-logo"
                    class="w-50"
                  />
                </div>

                <div class="mt-10 mb-10">
                  <h1
                    class="text-white"
                    style="letter-spacing: 5px"
                  >
                    บริการรับนำเข้าสินค้า
                  </h1>
                  <h3 class="text-white">
                    <span class="text-warning">จากจีนมาไทย</span> ทุกรูปแบบ
                  </h3>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="bg-login">
                <div class="mb-2">
                  <h2>ลืมรหัสผ่าน?</h2>
                </div>
                <validation-observer ref="loginValidation">
                  <div v-if="step === 1">
                    <small>ป้อนที่อยู่อีเมลของคุณด้านล่าง
                      ระบบจะส่งรหัสยืนยันเพื่อรีเซ็ตรหัสผ่านของคุณในขั้นตอนต่อไป</small>
                    <b-form
                      class="auth-login-form mt-2"
                      @submit.prevent
                    >
                      <!-- email -->
                      <b-form-group
                        label="Email"
                        label-for="login-email"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Email"
                          rules="required"
                        >
                          <b-form-input
                            id="login-email"
                            v-model="email"
                            type="email"
                            :state="errors.length > 0 ? false : null"
                            name="email"
                            placeholder="example: p2cargo@gmail.com"
                          />
                          <small class="text-danger">{{ errors[0] ? 'กรุณากรอก Email': statusemail ? statusemail : '' }}</small>
                        </validation-provider>
                      </b-form-group>

                      <!-- submit buttons -->
                      <!-- @keyup.enter="stepOne()" -->
                      <b-button
                        type="submit"
                        variant="primary"
                        block
                        size="lg"
                        :disabled="isDisabled"
                        @click="SearchEmail()"
                      >
                        ต่อไป
                      </b-button>
                    </b-form>
                  </div>

                  <div
                    v-if="step === 2"
                    class="text-center"
                  >
                    <small>กรอกรหัส OTP ที่ได้รับจาก Email ของท่าน</small>
                    <div
                      class="d-flex justify-content-center align-items-center mt-2"
                    >
                      <b-form-input
                        v-for="(digit, index) in otp"
                        :key="index"
                        ref="otpInput"
                        v-model="otp[index]"
                        type="number"
                        class="otp-input"
                        :maxlength="1"
                        @input="moveToNext(index)"
                        @keydown.backspace="moveToPrev(index, $event)"
                      />
                    </div>
                    <p class="mt-2 text-danger p-0">
                      OTP จะหมดอายุในอีก : {{ minutes }}:{{ seconds }}
                    </p>
                    <b-button
                      type="submit"
                      variant="primary"
                      block
                      size="lg"
                      :disabled="isDisabled"
                      @click="stepTwo()"
                    >
                      ต่อไป
                    </b-button>
                    <div class="mt-2">
                      ไม่ได้รับรหัสใช่หรือไม่? :
                      <span
                        class="text-primary font-weight-bolder cursor-pointer"
                        @click="SendOTP(2), otp = ['', '', '', '', '', '']"
                      >ส่ง OTP ใหม่</span>
                    </div>
                    <hr>
                  </div>

                  <div v-if="step === 3">
                    <b-form-group
                      label="รหัสผ่านใหม่"
                      label-for="rg-password"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Password"
                        rules="required"
                      >
                        <!-- <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        > -->
                        <b-form-input
                          id="rg-password"
                          v-model="password"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="············"
                        />
                        <!-- <b-input-group-append is-text>
                            <feather-icon
                              class="cursor-pointer"
                              :icon="passwordToggleIcon"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group> -->
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="ยืนยันรหัสผ่านใหม่"
                      label-for="rg-password-confirm"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Password Confirm"
                        rules="required|confirmed:Password"
                      >
                        <!-- <b-input-group
                          id="rg-password-confirm"
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        > -->
                        <b-form-input
                          v-model="passwordCon"
                          :state="errors.length > 0 ? false : null"
                          type="password"
                          placeholder="Confirm Password"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <!-- </b-input-group> -->
                      </validation-provider>
                    </b-form-group>
                    <b-button
                      type="submit"
                      variant="primary"
                      block
                      size="lg"
                      :disabled="isDisabled"
                      @click="confirmData()"
                    >
                      ยืนยัน
                    </b-button>
                  </div>
                </validation-observer>

                <b-row>
                  <b-col
                    cols="12"
                    class="text-center mt-2"
                  >
                    <span>หากมีบัญชีผู้ใช้แล้ว คุณสามารถ
                      <router-link
                        class="text-primary ms-2 mb-1 font-weight-bolder"
                        :to="{ name: 'login' }"
                      >เข้าสู่ระบบ</router-link></span>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <this-footer />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormGroup,
  // BInputGroup,
  // BInputGroupAppend,
  BRow,
  BCol,
  BForm,
  BFormInput,
  // BFormCheckbox,
  BButton,
  BImg,
  // BAvatar,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ThisFooter from './component/ThisFooter.vue'

export default {
  components: {
    ThisFooter,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    // BInputGroup,
    // BInputGroupAppend,
    BRow,
    BCol,
    BFormInput,
    // BFormCheckbox,
    BButton,
    // BAvatar,
    BImg,
    BForm,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      step: 1,
      rememberMe: false,
      isPasswordVisible: false,
      requiredValidator: true,
      otp: ['', '', '', '', '', ''],
      correctOTP: 222222,
      // validation rulesimport store from '@/store/index'
      required,
      email: '',
      timeLeft: 300,
      statusemail: null,
      password: null,
      passwordCon: null,
      isDisabled: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    minutes() {
      const min = Math.floor(this.timeLeft / 60)
      return min < 10 ? `0${min}` : min
    },
    seconds() {
      const sec = this.timeLeft % 60
      return sec < 10 ? `0${sec}` : sec
    },
  },
  watch: {
    otp: {
      handler(newOtp) {
        for (let i = 0; i < newOtp.length; i++) {
          if (newOtp[i].length > 1) {
            this.$set(this.otp, i, newOtp[i][0])
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$refs.otpInput[0].$el.focus()
  },
  methods: {
    SearchEmail() {
      this.isDisabled = true
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          const params = {
            email: this.email,
          }
          this.$http.get('/Check/email/Search', { params })
            // eslint-disable-next-line consistent-return
            .then(async response => {
              if (response.data.status) {
                this.statusemail = null
                // this.step = 3
                this.SendOTP(2)
              }
            })
            .catch(err => {
              console.log(err)
              this.statusemail = 'ไม่พบ email นี้อยู่ในระบบ'
              this.isDisabled = false
              // err.response.data.status
            })
        }
      })
    },
    SendOTP(type) {
      this.isDisabled = true
      const params = {
        email: this.email,
      }
      this.$http.get('/User/email/OTP', { params })
        .then(response => {
          if (response.data.status) {
            this.step = type
            this.timeLeft = 299
            this.startCountdown()
            this.isDisabled = false
          }
        })
        .catch(err => {
          console.log(err)
          this.SwalError('ส่งรหัส OTP ไม่สำเร็จ')
          this.isDisabled = false
          // err.response.data.status
        })
      // this.step = type
      // this.startCountdown()
    },
    moveToNext(index) {
      if (this.otp[index].length === 1 && index < 5) {
        this.$refs.otpInput[index + 1].$el.focus()
      }
    },
    moveToPrev(index, event) {
      if (event.key === 'Backspace' && this.otp[index] === '' && index > 0) {
        this.$refs.otpInput[index - 1].$el.focus()
      }
    },

    async stepOne() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.step = 2
          this.startCountdown()
        }
      })
    },
    stepTwo() {
      this.isDisabled = true
      const obj = {
        OTP: this.otp.join(''),
        email: this.email,
      }
      this.$http.post('/User/email/Submit', obj)
        .then(response => {
          if (response.data.status) {
            this.step = 3
            this.isDisabled = false
          }
        }).catch(err => {
          this.SwalError('รหัส OTP ไม่ถูกต้อง หรือรหัส OTP หมดอายุแล้ว')
          console.log(err)
          this.isDisabled = false
        })
      // if (this.otp.join('') === '222222') {
      //   this.step = 3
      // } else {
      //   alert('รหัส OTP ไม่ตรง หรือหมดอายุแล้ว กรุณากดรับ OTP ใหม่')
      //   this.otp = ['', '', '', '', '', '']
      // }
    },
    confirmData() {
      this.isDisabled = true
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          if (this.password !== this.passwordCon) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'รหัสผ่านไม่ตรงกัน',
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
          } else {
            const formData = {
              email: this.email,
              password: this.password,
              fotmatcode: this.otp.join(''),
            }
            this.$http.post('/User/format/password', formData)
              .then(res => {
                if (res.data.status) {
                  useJwt
                    .login({
                      username: res.data.username,
                      password: this.password,
                      agent_id: 1,
                    })
                    .then(response => {
                      const userData = response.data
                      useJwt.setToken(response.data.token)
                      useJwt.setRefreshToken(response.data.refreshToken)
                      localStorage.setItem(
                        'userData',
                        JSON.stringify(userData),
                      )
                      localStorage.setItem('CheckClick', true)
                      this.$ability.update(userData.ability)
                      this.$router
                        .push(getHomeRouteForLoggedInUser(userData))
                        .then(() => {
                          this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                              title:
                                'ยินดีต้อนรับเข้าสู่ ระบบบริการ นำเข้าสินค้าจากจีนมาไทย ทุกรูปแบบ',
                              icon: 'CoffeeIcon',
                              variant: 'success',
                              text: 'You have successfully logged ',
                            },
                          })
                        })
                        .catch(error => {
                          this.SwalError(error.response.data.message)
                          this.$refs.loginForm.setErrors(
                            error.response.data.message,
                          )
                          this.$router.push({ name: 'auth-forgot' })
                          this.isDisabled = false
                        })
                    })
                    .catch(error => {
                      this.SwalError(error.response.data.message)
                      this.$refs.loginForm.setErrors(
                        error.response.data.message,
                      )
                      this.$router.push({ name: 'auth-forgot' })
                      this.isDisabled = false
                    })
                }
              })
              .catch(error => {
                this.SwalError(error.response.data.message)
                this.$router.push({ name: 'auth-forgot' })
                this.isDisabled = false
              })
          }
        }
      })
    },

    onSubmit() {
      this.$refs.refVForm.validate().then(success => {
        if (success) {
          this.$router.push({ name: 'dashboard' })
        }
      })
    },
    startCountdown() {
      const countdownInterval = setInterval(() => {
        if (this.timeLeft > 0) {
          // eslint-disable-next-line no-plusplus
          this.timeLeft--
        } else {
          clearInterval(countdownInterval)
        }
      }, 1000)
    },
    SwalError(message) {
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        confirmButtonText: 'Cool',
      })
    },
  },
}
</script>

<style scope>
.otp-input {
  width: 40px;
  height: 40px;
  margin: 0 5px;
  text-align: center;
  font-size: 18px;
}
</style>
