<template>
  <div>
    <header class="h_header">
      <div class="h_top v-container d-flex align-items-center">
        <div class="h_logo">
          <!-- Logo goes here -->
          <!-- <img src="" alt=""> -->
          <img
            src="@/assets/images/logo/logo-p2-cargo.webp"
            alt="logo"
            title="anan-cargo-logo"
          />
        </div>

        <div class="h_right">
          <p class="m-0">ต้องการความช่วยเหลือ?</p>
        </div>
      </div>
    </header>
    <main>
      <div class="bg_content">
        <div class="bg_content2 p-md-5 p-1">
          <div class="row">
            <!-- <div class="col-md-3">
              <div class="text-center text-white h-100 mt-10">
                <div>
                  <b-img
                    src="@/assets/images/anan-img/svg/logo_anan.svg"
                    alt="logo"
                    title="anan-cargo-logo"
                    class="w-50"
                  />
                </div>

                <div class="mt-10 mb-10">
                  <h1
                    class="text-white font-weight-bolder"
                    style="letter-spacing: 5px"
                  >
                    บริการรับนำเข้าสินค้า
                  </h1>
                  <h3 class="text-white">
                    <span class="text-warning">จากจีนมาไทย</span> ทุกรูปแบบ
                  </h3>
                </div>
              </div>
            </div> -->

            <div class="col-md-12">
              <div class="bg-login">
                <div class="mb-2">
                  <h2 class="font-weight-bolder">สมัครใหม่</h2>
                </div>
                <validation-observer ref="loginValidation">
                  <div class="auth-login-form mt-2 row">
                    <!-- Phone number -->

                    <!-- Username , Email and Invtie Code -->
                    <div class="col-lg-6 col-sm-12 col-md-12">
                      <b-form-group
                        label="ชื่อผู้ใช้งาน"
                        label-for="ชื่อผู้ใช้งาน"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="username"
                          rules="required|englishNumber|lengthBetween|englishNumberNoSpecial"
                        >
                          <b-form-input
                            id="username"
                            v-model="username"
                            :state="errors.length > 0 ? false : null"
                            name="username"
                            placeholder="ชื่อผู้ใช้งาน"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <div class="row">
                        <div class="col-md-6">
                          <b-form-group
                            label="รหัสผ่าน"
                            label-for="rg-password"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Password"
                              rules="required"
                            >
                              <b-input-group
                                class="input-group-merge"
                                :class="errors.length > 0 ? 'is-invalid' : null"
                              >
                                <b-form-input
                                  id="rg-password"
                                  v-model="password"
                                  :state="errors.length > 0 ? false : null"
                                  class="form-control-merge"
                                  :type="passwordFieldType"
                                  name="login-password"
                                  placeholder="············"
                                />
                                <b-input-group-append is-text>
                                  <feather-icon
                                    class="cursor-pointer"
                                    :icon="passwordToggleIcon"
                                    @click="togglePasswordVisibility"
                                  />
                                </b-input-group-append>
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </div>

                        <div class="col-md-6">
                          <b-form-group
                            label="ยืนยันรหัสผ่าน"
                            label-for="rg-password-confirm"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Password Confirm"
                              rules="required|confirmed:Password"
                            >
                              <b-form-input
                                v-model="passwordCon"
                                :state="errors.length > 0 ? false : null"
                                type="password"
                                placeholder="Confirm Password"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </div>
                      </div>
                      <b-form-group
                        label="หมายเลขโทรศัพท์"
                        label-for="re-phone-number"
                      >
                        <b-form-input
                          id="re-phone-number"
                          v-model="phoneNumber"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          maxlength="10"
                          type="number"
                          name="re-phone-number"
                          placeholder="หมายเลขโทรศัพท์จำนวน 10 หลัก"
                          @keyup.enter="stepOne()"
                        />
                      </b-form-group>

                      <b-form-group label="อีเมล" label-for="login-email">
                        <validation-provider
                          #default="{ errors }"
                          name="Email"
                          rules="required"
                        >
                          <b-form-input
                            id="login-email"
                            v-model="your_email"
                            :state="errors.length > 0 ? false : null"
                            name="login-email"
                            placeholder="john@example.com"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group v-slot="{ ariaDescribedby }">
                        <template v-bind="label">
                          <span class="text-danger">*</span> กลุ่มผู้ใช้งาน
                        </template>
                        <v-select
                          v-model="selected"
                          label="name"
                          :options="radios"
                          :aria-describedby="ariaDescribedby"
                        />
                        <!-- <b-form-radio-group
                          v-model="selected"
                          :options="radios"
                          :reduce="name => name.name"
                          label="name"

                          name="radios-stacked"
                          stacked
                        /> -->
                      </b-form-group>

                      <b-form-group
                        label="รหัสผู้แนะนำ"
                        label-for="invite-code"
                      >
                        <b-form-input
                          id="invite-code"
                          v-model="inviteCode"
                          name="invite-code"
                          placeholder="Invite Code"
                        />
                      </b-form-group>

                      <!-- <b-button
                        type="submit"
                        variant="primary"
                        block
                        @click="stepTwo()"
                      >
                        ต่อไป
                      </b-button> -->
                    </div>

                    <!-- User Information -->

                    <div class="col-lg-6 col-sm-12 col-md-12">
                      <div class="row">
                        <div class="col-md-12">
                          <b-form-group
                            label="ชื่อ-นามสกุล"
                            label-for="rg-full-name"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Full Name"
                              rules="required"
                            >
                              <b-form-input
                                id="rg-full-name"
                                v-model="fullName"
                                :state="errors.length > 0 ? false : null"
                                name="fullName"
                                placeholder="Full Name"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group label="ไอดีไลน์" label-for="rg-line-id">
                            <b-form-input
                              id="rg-line-id"
                              v-model="lineId"
                              name="lineId"
                              placeholder="Line ID"
                            />
                          </b-form-group>
                        </div>

                        <div class="col-md-6">
                          <b-form-group label="ที่อยู่" label-for="rg-address">
                            <validation-provider
                              #default="{ errors }"
                              name="Address"
                              rules="required"
                            >
                              <b-form-input
                                id="rg-address"
                                v-model="address"
                                :state="errors.length > 0 ? false : null"
                                name="address"
                                placeholder="1/1"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </div>

                        <div class="col-md-12">
                          <b-form-group label="จังหวัด" label-for="rg-province">
                            <validation-provider
                              #default="{ errors }"
                              name="username"
                              rules="required"
                            >
                              <v-select
                                id="rg-province"
                                v-model="province"
                                :options="Provincelist"
                                :reduce="
                                  (province_name) => province_name.province_id
                                "
                                label="province_name"
                                placeholder="Select a Province..."
                                @input="GetDistrict(province)"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </div>

                        <div class="col-md-12">
                          <b-form-group label="อำเภอ" label-for="rg-district">
                            <validation-provider
                              #default="{ errors }"
                              name="district"
                              rules="required"
                            >
                              <v-select
                                id="rg-district"
                                v-model="district"
                                :options="districtlist"
                                :reduce="
                                  (district_name) => district_name.district_id
                                "
                                label="district_name"
                                placeholder="Select a District..."
                                @input="GetSubDistrict(district)"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </div>

                        <div class="col-md-12">
                          <b-form-group
                            label="ตำบล"
                            label-for="rg-sub-district"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="sub-district"
                              rules="required"
                            >
                              <v-select
                                id="rg-sub-district"
                                v-model="subDistrict"
                                :options="subDistrictlist"
                                :reduce="
                                  (sub_district_name) =>
                                    sub_district_name.tumbon_id
                                "
                                label="sub_district_name"
                                placeholder="Select a Sub District..."
                                @input="GetZipCode(subDistrict)"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </div>

                        <div class="col-md-12">
                          <b-form-group
                            label="รหัสไปรษณีย์"
                            label-for="rg-line-id"
                          >
                            <b-form-input
                              id="rg-zip-code"
                              v-model="zipcode"
                              name="zip-code"
                              placeholder=""
                              disabled
                            />
                          </b-form-group>
                        </div>
                      </div>
                    </div>
                  </div>
                  <b-col cols="12" class="text-center mt-2">
                    <small
                      >โดยการเปิดบัญชี P2 Cargo ท่านรับทราบและตกลงตาม</small
                    >
                    <br />
                    <small
                      v-b-modal.modal-no-animation
                      class="cursor-pointer text-success font-weight-bolder"
                      >เงื่อนไข และ ข้อตกลงการให้บริการ</small
                    ><br />
                    <b-form-checkbox
                      v-model="termStatus"
                      class="pt-1"
                      switch
                      inline
                    >
                      <b>ยอมรับเงื่อนไขและข้อตกลง</b>
                    </b-form-checkbox>
                  </b-col>
                  <div class="col-md-12 mt-2">
                    <b-button
                      :disabled="!termStatus"
                      type="submit"
                      variant="primary"
                      block
                      @click="confirmData()"
                    >
                      ยืนยัน
                    </b-button>
                    <!-- submit buttons -->
                  </div>
                </validation-observer>
                <!-- <div class="divider my-2">
                  <div class="divider-text">
                    or
                  </div>
                </div> -->
                <b-row>
                  <!-- <b-col
                    md="6"
                    cols="12"
                  >
                    <b-button
                      block
                      variant="outline-primary"
                      @click="notify"
                    >
                      <b-avatar
                        src="/icon/facebook.webp"
                        size="sm"
                      />
                      &nbsp; facebook
                    </b-button>
                  </b-col>

                  <b-col
                    md="6"
                    cols="12"
                  >
                    <b-button
                      block
                      variant="outline-primary"
                    >
                      <b-avatar
                        size="sm"
                        src="/icon/google.png"
                      />
                      &nbsp; Google
                    </b-button>
                  </b-col> -->

                  <b-col cols="12" class="text-center mt-2">
                    <p class="mt-1 mb-0">
                      หากมีบัญชีผู้ใช้แล้ว คุณสามารถ
                      <router-link
                        class="text-primary ms-2 mb-1 font-weight-bolder"
                        :to="{ name: 'login' }"
                        >เข้าสู่ระบบ</router-link
                      >
                    </p>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-modal
        id="modal-no-animation"
        content-class="shadow"
        title="เงื่อนไขและข้อตกลง"
        hide-footer
        size="lg"
      >
        <img src="@/assets\images\hd.jpg" width="100%" alt="" />
        <img src="@/assets\images\term.webp" width="100%" alt="" />
      </b-modal>
    </main>
    <this-footer />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import useJwt from "@/auth/jwt/useJwt";
import {
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BImg,
  BAvatar,
  BFormRadioGroup,
  BFormCheckbox,
  BModal,
} from "bootstrap-vue";
import { required, email, regex, between } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import ThisFooter from "./component/ThisFooter.vue";

extend("englishNumber", {
  ...regex,
  message: "กรุณากรอกข้อมูลเป็นภาษาอังกฤษและตัวเลข",
  validate: (value) => /^[A-Za-z0-9]+$/.test(value) && /[A-Za-z]/.test(value),
});
extend("englishNumberNoSpecial", {
  ...regex,
  message: "ห้ามระบุตัวอักษรพิเศษเช่น @ _ $ &",
  validate: (value) => /^[A-Za-z0-9]*$/.test(value),
});

extend("lengthBetween", {
  ...between,
  message: "กรุณากรอกข้อมูลมากกว่า 4 ตัวอักษร ไม่เกิน 10 ตัวอักษร",
  validate: (value) => value.length >= 4 && value.length <= 10,
});

export default {
  components: {
    ThisFooter,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    BImg,
    vSelect,
    BFormRadioGroup,
    BFormCheckbox,
    BModal,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      step: 1,
      phoneNumber: "",
      username: "",
      your_email: "",
      password: "",
      passwordCon: "",
      rememberMe: false,
      isPasswordVisible: false,
      requiredValidator: true,
      inviteCode: "",
      fullName: "",
      lineId: "",
      province: "",
      district: "",
      subDistrict: "",
      zipcode: "",
      Provincelist: [],
      districtlist: [],
      subDistrictlist: [],
      selected: null,
      termStatus: false,
      radios: [
        // { text: 'นำเข้าอย่างเดียว', value: '1' },
        // { text: 'พรีออเดอร์ (สั่งซื้อ + นำเข้า)', value: '2' },
      ],
      options: [
        { value: "bangkok", label: "Bangkok" },
        { value: "nonthaburi", label: "Nonthaburi" },
        { value: "nakhonpathom", label: "Nakhon Pathom" },
      ],

      // validation rulesimport store from '@/store/index'
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  mounted() {
    this.GetProvince();
    this.Groupbyuser();
  },
  methods: {
    GetProvince() {
      this.$http
        .post("/address/Province")
        .then((response) => {
          this.Provincelist = response.data;
        })
        .catch((error) => console.log(error));
    },
    GetDistrict(data) {
      const obj = {
        province_id: data,
      };
      this.$http
        .post("/address/District", obj)
        .then((response) => {
          this.districtlist = response.data;
        })
        .catch((error) => console.log(error));
    },
    GetSubDistrict(data) {
      const obj = {
        district_id: data,
      };
      this.$http
        .post("/address/SubDistrict", obj)
        .then((response) => {
          this.subDistrictlist = response.data;
        })
        .catch((error) => console.log(error));
    },
    GetZipCode(data) {
      const obj = {
        tumbon_id: data,
      };
      this.$http
        .post("/address/Zipcode", obj)
        .then((response) => {
          this.zipcode = response.data.zipcode;
        })
        .catch((error) => console.log(error));
    },
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Form Submitted",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      });
    },
    async stepOne() {
      if (this.phoneNumber.length === 10) {
        const params = {
          phoneNumber: this.phoneNumber,
        };
        this.$http
          .get("/users/checkData", { params })
          .then((response) => {
            if (response.data) {
              // this.step = 2;
            }
          })
          .catch((err) => {
            console.log(err);
            this.error("เบอร์โทรศัพท์นี้ถูกใช้งานแล้ว");
          });
      } else {
        this.error("กรุณากรอกเบอร์โทรศัพท์ให้ครบ 10 หลัก");
      }
    },
    stepTwo() {
      if (!this.username) {
        this.error("กรุณากรอกข้อมูลชื่อผู้ใช้");
      } else if (!this.selected) {
        this.error("กรุณากรอกข้อมูลให้ครบถ้วน");
      } else if (!this.your_email) {
        this.error("กรุณากรอกข้อมูลอีเมล");
      } else {
        this.$refs.loginValidation.validate().then((success) => {
          if (success) {
            const params = {
              username: this.username,
              inviteCode: this.inviteCode,
              email: this.your_email,
            };
            this.$http
              .get("/users/checkData", { params })
              .then((response) => {
                if (response.data) {
                  // this.step = 3;
                }
              })
              .catch((err) => {
                this.error(err.response.data);
                console.log(err);
              });
          }
        });
      }
    },
    // async CheckPhoneNumber(phoneNumber) {

    // },
    async Groupbyuser() {
      try {
        const { data: res } = await this.$http.get("/usertypes/list/index");
        this.radios = res;
        this.selected = this.radios[0];
        // console.log(this.radios)
      } catch (err) {
        console.log(err);
      }
    },
    //
    confirmData() {
      this.stepOne();
      this.stepTwo();
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          const checkusername = this.customValidation();
          if (!checkusername) {
            return;
          }
          if (this.password !== this.passwordCon) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "รหัสผ่านไม่ตรงกัน",
                icon: "EditIcon",
                variant: "warning",
              },
            });
          } else {
            const formData = {
              agent_id: 1,
              username: this.username,
              fullname: this.fullName,
              invited_by: this.inviteCode,
              tel: this.phoneNumber,
              line_id: this.lineId,
              address: this.address,
              province: this.province,
              province_name: "",
              district: this.district,
              district_name: "",
              subdistrict: this.subDistrict,
              subdistrict_name: "",
              password: this.password,
              zipcode: this.zipcode,
              selected: this.selected,
              email: this.your_email,
            };
            if (this.province) {
              const provi = this.Provincelist.find(
                (ele) => ele.province_id === this.province
              );
              formData.province_name = provi.province_name;
            }
            if (this.district) {
              const arr = this.districtlist.find(
                (ele) => ele.district_id === this.district
              );
              formData.district_name = arr.district_name;
            }
            if (this.subDistrict) {
              const arr = this.subDistrictlist.find(
                (ele) => ele.tumbon_id === this.subDistrict
              );
              formData.subdistrict_name = arr.sub_district_name;
            }
            this.$http
              .post("/users/store", formData)
              .then((res) => {
                if (res.data.status) {
                  useJwt
                    .login({
                      username: this.username,
                      password: this.password,
                      agent_id: 1,
                    })
                    .then((response) => {
                      const userData = response.data;
                      useJwt.setToken(response.data.token);
                      useJwt.setRefreshToken(response.data.refreshToken);
                      localStorage.setItem(
                        "userData",
                        JSON.stringify(userData)
                      );
                      localStorage.setItem("CheckClick", true);
                      this.$ability.update(userData.ability);
                      this.$router
                        .push(getHomeRouteForLoggedInUser(userData))
                        .then(() => {
                          this.$toast({
                            component: ToastificationContent,
                            position: "top-right",
                            props: {
                              title:
                                "ยินดีต้อนรับเข้าสู่ ระบบบริการ นำเข้าสินค้าจากจีนมาไทย ทุกรูปแบบ",
                              icon: "CoffeeIcon",
                              variant: "success",
                              text: "You have successfully logged ",
                            },
                          });
                        })
                        .catch((error) => {
                          this.SwalError(error.response.data.message);
                          this.$refs.loginForm.setErrors(
                            error.response.data.message
                          );
                        });
                    })
                    .catch((error) => {
                      this.SwalError(error.response.data.message);
                      this.$refs.loginForm.setErrors(
                        error.response.data.message
                      );
                    });
                }
              })

              .catch((error) => {
                this.SwalError(error.response.data.message);
              });
          }
        }
      });
    },
    customValidation() {
      if (this.username) {
        return (
          /^[A-Za-z0-9]+$/.test(this.username) &&
          /[A-Za-z]/.test(this.username) &&
          /^[A-Za-z0-9]*$/.test(this.username) &&
          this.username.length >= 4 &&
          this.username.length <= 10
        );
      }
      return false;
    },
    onSubmit() {
      this.$refs.refVForm.validate().then((success) => {
        if (success) {
          this.$router.push({ name: "dashboard" });
        }
      });
    },
    notify() {
      this.$vs.notify({
        title: "Facebook",
        text: "Facebook ยังไม่เปิดให้บริการ",
        color: "warning",
        position: "top-right",
      });
    },
    SwalError(mes) {
      this.$swal({
        icon: "error",
        title: `<h3 style="color: #141414">${this.$t("key-298")}</h3>`,
        text: mes,
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    },
    error(mes) {
      this.$swal({
        title: `<h3>${mes}</h3>`,
        text: "Do you want to continue",
        icon: "error",
        confirmButtonText: "OK",
      });
    },
    updateValue(event) {
      const { value } = event.target;
      console.log(value, this.amount);
      if (String(value).length <= 10) {
        this.amount = value;
      }
      this.$forceUpdate();
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
