<template>
  <b-overlay
    :show="showOver"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div>
      <div class="card">
        <div class="nav-panel anan-tabs anan-tabs-line anan-tabs-normal anan-tabs-top">
          <div class="anan-tabs__nav">
            <div
              class="anan-tabs__nav-warp px-2"
              style="margin-top: 5px;"
            >
              <div
                class="anan-tabs__nav-tabs"
                style="transform: translateX(0px)"
              >
                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'all' }"
                  style="white-space: normal"
                  @click="getData(status = 0), isActive = 'all'"
                >
                  <span> {{ $t('all') }} </span>
                </div>

                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'AllWaitDelivery' }"
                  style="white-space: normal"
                  @click="getData(status = 1), isActive = 'AllWaitDelivery'"
                >
                  <span> {{ $t('allPendingOrders') }} </span>
                </div>

                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'ThWaitDelivery' }"
                  style="white-space: normal"
                  @click="getData(status = 2), isActive = 'ThWaitDelivery'"
                >
                  <span> {{ $t('awaitingDeliveryTH') }} </span>
                </div>

                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'waiting' }"
                  style="white-space: normal"
                  @click="getData(status = 3), isActive = 'waiting'"
                >
                  <span> {{ $t('awaitingPickup') }} </span>
                </div>

                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'success' }"
                  style="white-space: normal"
                  @click="getData(status = 4), isActive = 'success'"
                >
                  <span> {{ $t('completed') }} </span>
                </div>
          
                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'cancel' }"
                  style="white-space: normal"
                  @click="getData(status = 5), isActive = 'cancel'"
                >
                  <span> {{ $t('cancel') }} </span>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="d-lg-flex justify-content-between align-items-center px-2">
          <div>
            <h3 class="font-weight-bolder">
              {{ $t('exportList') }}
            </h3>
          </div>

          <div class="d-flex align-items-center">
            <div class="anan-input__inner anan-input__inner--normal">
              <input
                v-model="search"
                type="text"
                :placeholder="$t('search')"
                class="anan-input__input"
                @keyup.enter="getData(status)"
              >
              <div class="anan-input__suffix">
                <button
                  type="button"
                  class="anan-button anan-button--normal anan-button--primary"
                  @click="getData(status)"
                >
                  <i class="fal fa-search" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="p-2">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :total-rows="totalRows"
            :items="items"
            :fields="fields"
            show-empty
          >
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner
                  label="กำลังโหลด..."
                  variant="danger"
                />
              </div>
            </template>

            <template #empty>
              <div class="text-center">
                <img
                  src="@/assets/images/anan-img/empty/empty.png"
                  alt="empty"
                  width="180px"
                >
                <p>No Data</p>
              </div>
            </template>
            <template #cell(itemnumber_text)="data">

              <b-link
                :to="{name: 'admin-export_edit', params: {id: data.item._id}}"
                target="_blank"
              >
                <!-- <span class="mb-0 text-black badge badge-warning"`````> {{ getType(data.item.transportType) }} </span> -->
                <span>{{ data.item.itemnumber_text }}</span>
              </b-link>

            </template>
            <template #cell(username)="data">
              <b-link
                :to="{name: 'admin-employee-edit', params: {id: data.item.uid}}"
                target="_blank"
              >
                <!-- <span class="mb-0 text-black badge badge-warning"`````> {{ getType(data.item.transportType) }} </span> -->
                <span>{{ data.item.username }}</span>
              </b-link>
            </template>
            <template #cell(deliverytype)="data">
              <span>{{ data.item.typedelivery.pro_name }}</span><br>
              <span :class="`${data.item.typedelivery.paydelivery === 'จ่ายค่าขนส่งต้นทาง' ? 'text-danger': 'text-warning'}`">{{ data.item.typedelivery.paydelivery }}</span>
            </template>
            <template #cell(delivery)="data">
              <span>{{ data.item.delivery.name }}</span><br>
            </template>
            <template #cell(status)="data">
              <span
                v-if="data.item.payNow === 3"
                class="badge badge-secondary badge-pill"
              > {{ $t('cancelled') }} </span>
              <span
                v-else-if="data.item.order_out === 1"
                class="badge badge-danger badge-pill"
              > {{ $t('awaitingPickup') }} </span>
              <span
                v-else-if="data.item.order_out === 2"
                class="badge badge-success badge-pill"
              > {{ $t('receivedItem') }} </span>
              <span
                v-else-if="data.item.order_thaiout === 1"
                class="badge badge-info badge-pill"
              > {{ $t('awaitingDeliveryTH') }} </span>
              <span
                v-else-if="data.item.order_thaiout === 2"
                class="badge badge-success badge-pill"
              > {{ $t('delivered') }} </span>

            </template>
            <template #cell(payment)="data">
              <p>{{ Commas(data.item.payment) }} ฿</p>
              <p class="text-danger">
                {{ data.item.Shipping_costth ? Commas(data.item.Shipping_costth): 0 }} ฿
              </p>
            </template>
            <template #cell(created_at)="data">
              {{ time(data.item.created_at) }}
            </template>
            <template #cell(approvalDate)="data">
              {{ data.item.out_thaiwarehouse ? data.item.out_thaiwarehouse: '-' }}
              <!-- {{ data.item.out_thaiwarehouse ? time(data.item.out_thaiwarehouse): '-' }} -->
            </template>
            <!-- <template #cell(index)="data">
            {{ perPage * (currentPage - 1) + (data.index + 1) }}
          </template>

          <template #cell(transactionDate)="data">
            {{ time(data.item.transactionDate) }}
          </template>

          <template #cell(amount)="data">
            {{ Commas(data.item.amount) }}฿
          </template>

          <template #cell(document)="data">
            <img
              :src="data.item.document"
              alt="img-document"
              class="border"
              height="40"
            >
          </template>  -->

            <!-- <template #cell(status)="data">
            <span
              v-if="data.item.status === 'waiting'"
              class="badge badge-primary badge-pill"
            > รอตรวจสอบ </span>

            <span
              v-if="data.item.status === 'success'"
              class="badge badge-success badge-pill"
            > ดำเนินการสำเร็จ </span>
          </template> -->

            <template #cell(actions)="data">
              <div class="d-flex">
                <router-link
                  :to="{name : 'admin-export_edit', params: {id:data.item._id}}"
                  target="_blank"
                >
                  <feather-icon
                    icon="Edit3Icon"
                    class="mr-1 text-primary cursor-pointer"
                  />
                </router-link>

                <feather-icon
                  icon="PrinterIcon"
                  class="text-success cursor-pointer"
                  @click="printpdf(data.item)"
                />
              </div>
            </template>
            <template #cell(fileimg)="data">
              <img
                v-if="data.item.fileimg_th"
                :src="GetImg('paymentfile',data.item.fileimg_th)"
                accept=".jpg, .png, .jpeg"
                alt="รูปภาพ"
                class="aws-avatar aws aws-avatar-square aws-avatar-image bordered cursor-pointer"
                @click="showPicture(data.item.fileimg_th)"
              >
            </template>
          </b-table>

          <div class="demo-spacing-0 d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @input="getData(status)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>

      </div>
      <!-- modal -->
      <b-modal
        ref="my-modal"
        hide-footer
        title="รูปภาพสินค้า"
      >
        <img
          :src="pic"
          alt="..."
          class="img-fluid rounded"
        >

        <div class="text-center">
          <button
            type="button"
            class="btn btn-primary mt-2"
            @click="downloadImg"
          >
            Download
          </button>
        </div>
      </b-modal>
    </div>

    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
    />
  </b-overlay>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'
import {
  BPagination, BModal, BTable, BSpinner, BOverlay, BLink,
} from 'bootstrap-vue'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  components: {
    BPagination,
    BLink,
    BModal,
    BTable,
    BSpinner,
    BOverlay,
    VueImageLightboxCarousel,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      search: null,
      isActive: 'all',
      selectedRadio: 'active',
      optionsRadio: [
        { text: 'All', value: 'all' },
        { text: 'Car', value: 'car' },
        { text: 'Ship', value: 'ship' },
      ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,

      items: [
        // {
        //   id: 1,
        //   number: 'GUSR-0001',
        //   deliveryNumber: 'GUSR-0001',
        //   customer: 'GUSR-0001',
        //   transactionDate: 'GUSR-0001',
        //   approvalDate: '-',
        //   noun: 'บริษัท ชิดา คอมเมอร์เชียล (ประเทศไทย) จำกัด',
        //   document: 'https://plmcdn.azureedge.net/upload/images/receipt/7a796ac0.jpg',
        //   customerType: 'บุคคลธรรมดา',
        //   amount: 100000,
        //   status: 'waiting',
        // },

        // {
        //   id: 2,
        //   number: 'GUSR-0001',
        //   deliveryNumber: 'GUSR-0001',
        //   customer: 'GUSR-0001',
        //   transactionDate: 'GUSR-0001',
        //   approvalDate: '-',
        //   noun: 'บริษัท ชิดา คอมเมอร์เชียล (ประเทศไทย) จำกัด',
        //   document: 'https://plmcdn.azureedge.net/upload/images/receipt/72655903.jpg',
        //   customerType: 'นิติบุคคล',
        //   amount: 100000,
        //   status: 'success',
        // },
      ],
      pic: null,
      showOver: false,

      total: 0,
      notpaid: 0,
      waitingforcheck: 0,
      waitingforpayment: 0,
      paid: 0,
      OrderSuccess: 0,
      completetrack: 0,
      completewarehouse: 0,
      cancel: 0,
      status: 0,
      status_thai: null,
      status_out: null,
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'itemnumber_text', label: this.$t('number'), thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'username', label: this.$t('customer'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'address.fullname', label: `${this.$t('firstName')}-${this.$t('lastName')}`, sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'created_at', label: this.$t('creationDate'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'approvalDate', label: this.$t('exportDate'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'deliverytype', label: this.$t('deliveryType'), sortable: false, thStyle: { width: '15%' }, thClass: 'text-center',
        },
        {
          key: 'delivery', label: this.$t('sender'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'search_name', label: this.$t('finder'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'pack_name', label: this.$t('packer'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'fileimg', label: this.$t('transactionEvidence'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'payment', label: this.$t('quantity'), sortable: false, thStyle: { width: '20%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'status', label: this.$t('status'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'actions', label: this.$t('manage'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  watch: {
    '$route.query.querykey': {
      immediate: true,
      handler() {
        this.handleQueryChange()
      },
    },
  },
  mounted() {
    this.handleQueryChange()
  },
  methods: {
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: this.GetImg('paymentfile', picture) }
      this.images.push(img)
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    handleQueryChange() {
      const { querykey } = this.$route.query
      if (querykey) {
        if (Number(querykey) === 1) {
          this.isActive = 'AllWaitDelivery'
          this.getData(this.status = 1, this.search_val)
        }
      } else {
        this.getData(this.status, this.search_val)
      }
    },
    // Commas(num) {
    //   return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    // },
    time(data) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    GetNotify() {
      this.$http
        .get('/order/Notify')
        .then(response => {
          // console.log(response.data)
          this.total = response.data.total
          this.waitingforcheck = response.data.waitingforcheck
          this.waitingforpayment = response.data.waitingforpayment
          this.paid = response.data.paid
          this.OrderSuccess = response.data.OrderSuccess
          this.completetrack = response.data.completetrack
          this.completewarehouse = response.data.completewarehouse
          this.cancel = response.data.cancel
        })
        .catch(error => console.log(error))
    },
    // eslint-disable-next-line camelcase
    getData(type) {
      this.showOver = true
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        search: this.search,
        type,
      }
      this.$http.get('/Paymentuser/getExport', { params })
        .then(response => {
          // response.data.data.map(ele => { ele.fileimg_th = null })
          this.items = response.data.data
          this.totalRows = response.data.total
          // totalRows
          this.showOver = false
          // this.ShowIMG()
        })
        .catch(err => {
          console.log(err)
          this.showOver = false
        })

      // console.log(this.items)
      // const params = {
      //   page: this.currentPage,
      //   perPage: this.perPage,
      // }
      // // console.log(params)
      // this.$http
      //   .get('/Paymentuser/getExport', params)
      //   .then(response => {
      //     console.log(response)
      //     this.items = response.data.data
      //     // this.totalRows = this.items.length
      //     // this.totalRows = response.data.total
      //   })
      //   .catch(error => console.log(error))
    },
    async ShowIMG() {
      const Id = this.items.map(ele => ele._id)
      const params = {
        id: Id,
      }
      this.$http.get('/Paymentuser/GetDataimg', { params })
        .then(response => {
          // console.log(response)
          response.data.forEach((data, index) => {
            this.items[index].fileimg_th = data.fileimg_th
          })
        })
        .catch(err => console.log(err))
      // Paymentuser/GetDataimg
    },
    GetOrderAll(type) {
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        type,
      }

      this.$http
        .get('/Paymentuser/getExport', { params })
        .then(response => {
          // console.log(response.data)
          this.items = response.data.data
          // this.totalRows = this.items.length
          this.totalRows = response.data.total
        })
        .catch(error => console.log(error))
    },
    resize(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = 'auto'
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = `${event.target.scrollHeight}px`
    },
    updateKeyword(id, note) {
      // console.log('Item ID:', id)
      // console.log('note:', note)
      const params = {
        id,
        note,
      }
      // console.log(params)
      this.$http
        .post('/order/updateNote', params)
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    printpdf(data) {
      try {
        // eslint-disable-next-line no-underscore-dangle
        window.open(`https://api.p2cargo.com/api/print/ExportProductsAdminPdf/${data._id}`)
      } catch (e) {
        console.log(e)
      }
    },
    showModal(data) {
      this.pic = data
      this.$refs['my-modal'].show()
    },
    downloadImg() {
      const imageURL = '@/assets/images/anan-img/example/1.jpg'
      const link = document.createElement('a')
      link.href = imageURL
      link.download = 'image.jpg'
      link.click()
    },
  },
}
</script>

      <style lang="scss" scoped></style>
