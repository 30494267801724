<template>
  <div>
    <!-- <this-header /> -->
    <div class="anan-set-nav-conttainer mt-150">
      <!-- <router-link :to="{name : 'new-pages'}">
        <b-button
          class="btn btn-gradient-primary mb-1 mr-50 mr-50 d-md-none"
        >
          <i class="fas fa-arrow-left mr-25" /> {{ $t('key-126') }}
        </b-button>
      </router-link> -->
      <b-overlay
        :show="show"
        variant="transparent"
        opacity="0.99"
        blur="5px"
        rounded="sm"
      >
        <template #overlay>
          <div class="text-center">
            <p id="cancel-label">
              กรุณารอสักครู่...
            </p>
          </div>
        </template>
        <div>
          <div
            class="VYJdTQ"
          >
            <a
              class="OFl2GI"
              title="ทั้งหมด"
              href="javascript:void(0)"
              :class="{ gAImis: isActive === 0 }"
              @click="GetOrderAll(status = 0)"
            > <span v-if="isActive === 0" class="buttoncs ">{{ $t('all') }} ( {{ total }} )</span>
   <span v-else class="_20hgQK">{{ $t('all') }}<span class="fSW3m4">( {{ total }} )</span></span>
           
            </a>

            <a
              class="OFl2GI"
              :title="$t('pendingReview')"
              href="javascript:void(0)"
              :class="{ gAImis: isActive === 1 }"
              @click="GetOrderAll(status = 1)"
            > <span v-if="isActive === 1" class="buttoncs text-nowrap">{{ $t('pendingReview') }} ( {{ waitingforcheck }} )</span>
   <span v-else class="_20hgQK">{{ $t('pendingReview') }}<span class="fSW3m4">( {{ waitingforcheck }} )</span></span>
             
            </a>

            <a
              class="OFl2GI"
              :title="$t('toPay')"
              href="javascript:void(0)"
              :class="{ gAImis: isActive === 2 }"
              @click="GetOrderAll(status = 2)"
            ><span v-if="isActive === 2" class="buttoncs ">{{ $t('toPay') }} ( {{ waitingforpayment }} )</span>
   <span v-else class="_20hgQK">{{ $t('toPay') }}<span class="fSW3m4">( {{ waitingforpayment }} )</span></span>
            
            </a>

            <a
              class="OFl2GI"
              :title="$t('paid')"
              href="javascript:void(0)"
              :class="{ gAImis: isActive === 3 }"
              @click="GetOrderAll(status = 3)"
            ><span v-if="isActive === 3" class="buttoncs ">{{ $t('paid') }} ( {{ paid }} )</span>
   <span v-else class="_20hgQK">{{ $t('paid') }}<span class="fSW3m4">( {{ paid }} )</span></span>
         
            </a>

            <a
              class="OFl2GI"
              :title="$t('key-94')"
              href="javascript:void(0)"
              :class="{ gAImis: isActive === 4 }"
              @click="GetOrderAll(status = 4)"
            ><span v-if="isActive === 4" class="buttoncs ">{{ $t('orderSuccess') }} ( {{ OrderSuccess }} )</span>
   <span v-else class="_20hgQK">{{ $t('orderSuccess') }}<span class="fSW3m4">( {{ OrderSuccess }} )</span></span>
          
            </a>

            <a
              class="OFl2GI"
              :title="$t('fullTrack')"
              href="javascript:void(0)"
              :class="{ gAImis: isActive === 5 }"
              @click="GetOrderAll(status = 5)"
            ><span v-if="isActive === 5" class="buttoncs ">{{ $t('fullTrack') }} ( {{ completetrack }} )</span>
   <span v-else class="_20hgQK">{{ $t('fullTrack') }}<span class="fSW3m4">( {{ completetrack }} )</span></span>
            
            </a>

            <a
              class="OFl2GI"
              :title="$t('completeWarehouse')"
              href="javascript:void(0)"
              :class="{ gAImis: isActive === 6 }"
              @click="GetOrderAll(status = 6)"
            ><span v-if="isActive === 6" class="buttoncs ">{{ $t('arrivedChinaWarehouse') }} ( {{ completewarehouse }} )</span>
   <span v-else class="_20hgQK">{{ $t('arrivedChinaWarehouse') }}<span class="fSW3m4">( {{ completewarehouse }} )</span></span>
           
            </a>

            <a
              class="OFl2GI"
              :title="$t('completeWarehouse')"
              href="javascript:void(0)"
              :class="{ gAImis: isActive === 8 }"
              @click="GetOrderAll(status = 8)"
            ><span v-if="isActive === 8" class="buttoncs text-nowrap">{{ $t('leftChinaWarehouse') }} ( {{ chinesewarehouse }} )</span>
   <span v-else class="_20hgQK">{{ $t('leftChinaWarehouse') }}<span class="fSW3m4">( {{ chinesewarehouse }} )</span></span>
              
            </a>

            <a
              class="OFl2GI"
              :title="$t('completeWarehouse')"
              href="javascript:void(0)"
              :class="{ gAImis: isActive === 9 }"
              @click="GetOrderAll(status = 9)"
            ><span v-if="isActive === 9" class="buttoncs text-nowrap">{{ $t('arrivedThaiWarehouse') }} ( {{ thaiwarehouse }} )</span>
   <span v-else class="_20hgQK">{{ $t('arrivedThaiWarehouse') }}<span class="fSW3m4">( {{ thaiwarehouse }} )</span></span>
             
            </a>

            <a
              class="OFl2GI"
              :title="$t('leftWarehouseThai')"
              href="javascript:void(0)"
              :class="{ gAImis: isActive === 10 }"
              @click="GetOrderAll(status = 10)"
            ><span v-if="isActive === 10" class="buttoncs ">{{ $t('leftWarehouseThai') }} ( {{ thaiwarehouseExit }} )</span>
   <span v-else class="_20hgQK ">{{ $t('leftWarehouseThai') }}<span class="fSW3m4"> <br>( {{ thaiwarehouseExit }} )</span></span>
             
            </a>

            <a
              class="OFl2GI"
              :title="$t('cancel')"
              href="javascript:void(0)"
              :class="{ gAImis: isActive === 7 }"
              @click="GetOrderAll(status = 7)"
            ><span v-if="isActive === 7" class="buttoncs ">{{ $t('cancel') }} ( {{ cancel }} )</span>
   <span v-else class="_20hgQK">{{ $t('cancel') }}<span class="fSW3m4">( {{ cancel }} )</span></span>
            
            </a>
          </div>

          <div class="VrgkXA">
            <i class="fal fa-search" />
            <input
              v-model="search"
              autocomplete="off"
              :placeholder="$t('key-95')"
              @input="GetOrderAll(status)"
            >
          </div>

          <!-- {{ items }} -->
          <div
            v-for="(item, index) in items"
            :key="index"
            class="hiXKxx"
          >

            <div
              :style="`background-color :${
                item.status === 2 ? '#ffe7e7' : '#fff'
              } !important`"
            >
              <div class="x0QT2k">
                <div class="KrPQEI">
                  <div class="qCUYY8">
                    <div class="_0RxYUS" />
                    <div class="_9Ro5mP">
                      <b-link
                        :to="{name : 'order-detail', params: {id : item._id}}"
                        class="text-dark"
                        target="_blank"
                      >
                        ORD-{{ item.orderId_text }}<br>
                      </b-link>
                    </div>
                    <div class="yHlRfY">
                      <b-link
                        :to="{name : 'order-detail', params: {id : item._id}}"
                        target="_blank"
                      >
                        <button class="stardust-button stardust-button--primary">
                          <span>{{ $t('viewDetails') }}</span>
                        </button>
                      </b-link>
                    </div>
                    <div class="_7wKGws">
                      <button
                        v-if="item.delivery_type == 1"
                        class="stardust-button"
                      >
                        <span>{{ $t('byTruck') }} <i class="fas fa-shipping-fast" /></span>
                      </button>
                      <button
                        v-if="item.delivery_type == 2"
                        class="stardust-button"
                      >
                        <span>{{ $t('byship') }} <i class="fas fa-ship text-info" /></span>
                      </button>
                    </div>
                  </div>
                  <div class="EQko8g">
                    <div class="qP6Mvo d-none d-md-block">
                      <a
                        class="KmBIg2"
                        href="javascript:void(0)"
                      ><span class="nkmfr2"> {{ $t('transactionStatus') }} </span></a>
                      <div
                        id="pc-drawer-id-153"
                        class="anan-drawer"
                        tabindex="0"
                      />
                    </div>
                    <div class="V+w7Xs">
                      <span
                        v-if="item.status === 1"
                        class="text-warning"
                      >
                        {{ $t('pendingReview') }}
                      </span>

                      <span
                        v-if="item.status === 2"
                        class="text-danger"
                      >
                        {{ $t('key-96') }}
                      </span>

                      <span
                        v-if="item.status === 3"
                        class="text-info"
                      >
                        {{ $t('paidAmount') }}
                      </span>

                      <span
                        v-if="item.status === 4"
                        class="text-success"
                      >
                        {{ $t('orderSuccess') }}
                      </span>

                      <span
                        v-if="item.status === 5"
                        class="text-info"
                      >
                        {{ $t('fullTrack') }}
                      </span>

                      <span
                        v-if="item.status === 6"
                        class="text-info"
                      >
                        {{ $t('completeWarehouse') }}
                      </span>

                      <span
                        v-if="item.status === 7"
                        class="text-danger"
                      >
                        {{ $t('cancel') }}
                      </span>
                      <span
                        v-if="item.status === 8"
                        class="text-warning"
                      >
                        {{ $t('leftChinaWarehouse') }}
                      </span>
                      <span
                        v-if="item.status === 9"
                        class="text-warning"
                      >
                        {{ $t('arrivedThaiWarehouse') }}
                      </span>
                       <span
                        v-if="item.status === 10"
                        class="text-warning"
                      >
                        {{ $t('leftWarehouseThai') }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="FycaKn" />
                <div class="_0OiaZ-">
                  <div class="FbLutl">
                    <div>
                      <span
                        class="x7nENX"
                      >
                        <div class="aybVBK">
                          <div class="rGP9Yd">
                            <div class="anan-image__wrapper">
                              <div class="anan-image__place-holder" />

                              <div
                                class="anan-image__content rounded cursor-pointer"
                                :style="`background-image: url('${item.shops[0] ? item.shops[0].products[0].picture : ''}');`"
                                @click="showPicture(item.shops[0].products[0].picture)"
                              >
                                <div class="anan-image__content--blur" />
                              </div>
                            </div>
                          </div>
                          <div class="_7uZf6Q">
                            <div>
                              <div class="iJlxsT">
                                <textarea
                                  ref="textarea"
                                  v-model="item.note"
                                  rows="1"
                                  class="ant-input ghost ant-input-sm sss"
                                  @input="resize"
                                  @change="updateKeyword(item._id, item.note)"
                                />
                              </div>
                            </div>
                            <div>
                              <div class="vb0b-P" />
                              <div class="_3F1-5M">x{{ item.quantity_total }}</div>
                            </div>
                            <small class="text-warning">{{ $t('time') }} <small class="text-muted">{{ time(item.updated_at) }}</small></small>
                          </div>
                        </div>
                        <div class="_9UJGhr">
                          <div class="rjqzk1">
                            <p>{{ $t('shopCount') }} : {{ Commas(item.shops.length) }}</p>
                            <p>{{ $t('noParcelCode') }} : {{ Commas(item.shops.reduce((acc, shop) => acc + (!shop.show && shop.have_postnumber ? 0 : shop.show ? 0 : 1), 0)) }}</p>
                            <p>{{ $t('notInWarehouse') }} : {{ Commas(item.shops.reduce((acc, shop) => acc + (!shop.show && shop.come_chinesewarehouse ? 0 : shop.show ? 0 : 1), 0)) }}</p>
                            <hr>
                            <p class="font-weight-bolder">
                              {{ $t('totalGoods') }} :  {{ Commas(item.shops.reduce((acc, shop) => acc + (shop.products.reduce((bf, ele) => bf + (ele.price * ele.quantity), 0)), 0) * item.rate_yuan ) }} ฿
                            </p>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div class="Cde7Oe" />
                  </div>
                </div>
              </div>
            </div>

            <div class="O2KPzo">
              <div class="mn7INg xFSVYg" />
              <div class="mn7INg EfbgJE" />
            </div>
            <div class="kvXy0c">
              <div class="-78s2g">
                <span class="JMmT2C"><div class="IlORNJ" /></span>
                <div class="_0NMXyN">
                  {{ $t('key-97') }}:
                </div>
                <div class="DeWpya">
                  ฿ {{ Commas( item.price_total ? item.price_total : 0 ) }}
                </div>
              </div>
            </div>

            <div
              v-if="item.status === 1 || item.status === 2 || item.status === 3"
              class="AM4Cxf"
            >

              <div class="qtUncs">
                <span
                  class="OwGaHA"
                >{{ $t('confirmAfterReceiving') }}</span>
              </div>
              <div class="EOjXew _4IR9IT">
                <div class="PF0-AU">
                  <button
                    v-if="item.status === 2"
                    class="stardust-button stardust-button--primary WgYvse"
                    @click="Pay(item)"
                  >
                    {{ $t('makePayment') }}
                  </button>
                </div>
                <div class="PgtIur">
                  <button
                    v-if="item.status === 1"
                    class="stardust-button stardust-button--secondary WgYvse"
                    @click="CancelOrder(item._id)"
                  >
                    {{ $t('cancelOrder') }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="demo-spacing-0 d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @input="GetOrderAll(status)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
          <!-- modal -->
          <b-modal
            ref="my-modal"
            hide-footer
            :title="$t('key-34')"
          >
            <img
              :src="pic"
              alt="..."
              class="img-fluid rounded"
            >

            <div class="text-center">
              <button
                type="button"
                class="btn btn-primary mt-2"
                @click="downloadImg"
              >
                Download
              </button>
            </div>
          </b-modal>
        </div>
      </b-overlay>
    </div>

    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
      
    />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'
import {
  BPagination,
  // BFormRadioGroup,
  BLink,
  // BBadge,
  BModal,
  BOverlay,
  // BIconController,
} from 'bootstrap-vue'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'

export default {
  components: {
    BPagination,
    // BFormRadioGroup,
    BLink,
    // BBadge,
    BModal,
    BOverlay,
    VueImageLightboxCarousel,
    // BIconController,
    // ThisHeader,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      show: false,
      showOverlay: false,
      imgList: '',
      search: null,
      isActive: 'all',
      selectedRadio: 'active',
      optionsRadio: [
        { text: 'All', value: 'all' },
        { text: 'Car', value: 'car' },
        { text: 'Ship', value: 'ship' },
      ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      items: [],
      pic: null,

      total: 0,
      waitingforcheck: 0,
      waitingforpayment: 0,
      paid: 0,
      OrderSuccess: 0,
      completetrack: 0,
      chinesewarehouse: 0,
      thaiwarehouse: 0,
      thaiwarehouseExit: 0,
      completewarehouse: 0,
      cancel: 0,
      Rate: 0,
      status: 0
    }
  },
  mounted() {
    this.GetNotify()
    this.GetOrderAll(this.status)
    // this.GetRate()
  },
  methods: {
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: picture }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    time(data) {
      return moment(data).format('YYYY-MM-DD HH:mm:ss')
    },
    Pay(data) {
      this.$swal({
        title: 'ยืนยันที่จะชำระเงิน ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.show = true
          const params = {
            // eslint-disable-next-line no-underscore-dangle
            ID: data._id,
          }
          this.$http
            .post('/order/confirmpay', params)
          // eslint-disable-next-line no-unused-vars
            .then(response => {
              // console.log(response.data)
              this.Success('ชำระเงินสำเร็จ')
              this.show = false
              this.GetNotify()
              this.GetOrderAll(this.status)
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
              this.show = false
            })
        } else {
          this.$swal('ยกเลิก', 'ยกเลิกคำสั่ง !', 'error')
        }
      })
    },
    CancelOrder(ID) {
      this.$swal({
        title: 'ยืนยันที่จะยกเลิกรายการ ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.show = true
          const params = {
            // eslint-disable-next-line no-underscore-dangle
            ID,
          }
          this.$http
            .post('/order/cancelOrder', params)
          // eslint-disable-next-line no-unused-vars
            .then(response => {
              // console.log(response.data)
              this.Success('ยกเลิกรายการสำเร็จ')
              this.show = false
              this.GetNotify()
              this.GetOrderAll(this.status)
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
              this.show = false
            })
        } else {
          this.$swal('ยกเลิก', 'ยกเลิกคำสั่ง !', 'error')
        }
      })
    },
    async GetOrderAll(type) {
      try {
        this.show = true
        const params = {
          page: this.currentPage,
          perPage: this.perPage,
          orderId_text: this.search,
          type,
        }
        const { data: res } = await this.$http.get('/order/OrderByIdCus', { params })
        this.items = res.data
        this.totalRows = res.total
        this.isActive = type
        this.GetNotify(this.search,type )
        this.show = false
      } catch (error) {
        console.log(error)
      }
    },
    GetOrderDetail() {
      const params = {
        ID: this.$route.params.id,
      }
      this.$http
        .post('/order/OrderById', params)
        .then(response => {
          // console.log(response.data)
          this.details = response.data

          console.log(this.details)
        })
        .catch(error => console.log(error))

      console.log(this.$route.params.id)
    },
    GetNotify(search, type) {
      const params = {
        type,
        search
      }
      this.$http
        .get('/order/NotifyByCus', {params})
        .then(response => {
          // console.log(response.data)
          this.total = response.data.total
          this.waitingforcheck = response.data.waitingforcheck
          this.waitingforpayment = response.data.waitingforpayment
          this.paid = response.data.paid
          this.OrderSuccess = response.data.OrderSuccess
          this.completetrack = response.data.completetrack
          this.thaiwarehouse = response.data.thaiwarehouse
          this.thaiwarehouseExit = response.data.thaiwarehouseExit
          this.chinesewarehouse = response.data.chinesewarehouse
          this.completewarehouse = response.data.completewarehouse
          this.cancel = response.data.cancel
        })
        .catch(error => console.log(error))
    },
    resize(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = 'auto'
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = `${event.target.scrollHeight}px`
    },
    updateKeyword(id, note) {
      // console.log('Item ID:', id)
      // console.log('note:', note)
      const params = {
        id,
        note,
      }
      // console.log(params)
      this.$http
        .post('/order/updateNote', params)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    showModal(data) {
      this.pic = data
      this.$refs['my-modal'].show()
    },
    downloadImg() {
      const imageURL = '@/assets/images/anan-img/example/1.jpg'
      const link = document.createElement('a')
      link.href = imageURL
      link.download = 'image.jpg'
      link.click()
    },
    async GetRate() {
      try {
        const { data: res } = await this.$http.get('/rate')
        this.Rate = res.rate_preorder
      } catch (error) {
        console.log(error)
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .VYJdTQ {
    overflow-x: auto;
    font-size: 12px !important;
  }
  .OFl2GI {
    flex: none !important;
    width:120px !important;
  }
}
</style>
