<template>
  <!-- form -->
  <validation-observer ref="simpleRules">
    <b-row>
      <b-col md="2" />
      <b-col md="8">
        <b-card>
          <b-card-text>
            <h4 class="card-title mb-0">
              {{ $t('generalInfo') }}
            </h4>
          </b-card-text>
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group label-for="h-product-name" label-cols-md="3">
                  <template v-slot:label>
                    {{ $t('productName') }} <span class="text-danger">*</span>
                  </template>
                  <validation-provider #default="{ errors }" name="Product Name" rules="required">
                    <b-form-input id="h-product-name" v-model="name" :state="errors.length > 0 ? false : null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label-for="h-catagory-name" label-cols-md="3">
                  <template v-slot:label>
                    {{ $t('category') }} <span class="text-danger">*</span>
                  </template>
                  <validation-provider #default="{ errors }" name="Catagory" rules="required">
                    <div class="d-flex align-items-center">
                      <v-select id="h-catagory-name" v-model="category"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :state="errors.length > 0 ? false : null"
                        label="name" :options="items" class="w-100 mr-1" />
                      <i class="far fa-edit text-primary cursor-pointer" @click="showModal" />
                    </div>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label-for="h-product-id" label-cols-md="3">
                  <template v-slot:label>
                    {{ $t('productCode') }} <span class="text-danger">*</span>
                  </template>
                  <validation-provider #default="{ errors }" name="Product ID" rules="required">
                    <b-form-input id="h-product-id" v-model="code" :state="errors.length > 0 ? false : null"
                      type="text" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- <b-col>
                <b-form-group
                  label-for="h-files-name"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    เพิ่มภาพสินค้า <span class="text-danger">*</span>
                  </template>
                  <b-form-file
                    id="h-files-name"
                    v-model="files"
                    placeholder="Choose files or drop them here..."
                    drop-placeholder="Drop files here..."
                    multiple
                    accept="image/*"
                    @change="handleFileUpload"
                  />
                </b-form-group>

                <div
                  v-if="uploadedImages.length"
                  class="image-thumbnails mb-1"
                >
                  <div
                    v-for="(image, index) in uploadedImages"
                    :key="index"
                    class="image-container"
                  >
                    <img
                      :src="image.url"
                      alt="Uploaded Image"
                    >
                    <button
                      class="delete-button"
                      @click="deleteImage(index)"
                    >
                      <i class="fa fa-times" />
                    </button>
                  </div>
                </div>
              </b-col> -->

              <b-col>
                <b-form-group label-for="h-files-name" label-cols-md="3">
                  <template v-slot:label>
                    {{ $t('addProductImage') }} <span class="text-danger">*</span>
                  </template>
                  <b-form-file v-model="selectedFiles" :state="Boolean(selectedFiles.length)" multiple
                    @change="handleFileSelect" />

                  <div v-if="selectedFiles.length">
                    <div v-for="file in selectedFiles" :key="file.name"
                      class="image-container d-flex align-items-center">
                      <img :src="file.preview" height="100">
                    </div>
                  </div>

                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group label-for="h-detail" label-cols-md="3">
                  <template v-slot:label>
                    {{ $t('productDetails') }} <span class="text-danger">*</span>
                  </template>
                  <b-form-textarea id="h-detail" v-model="details" rows="3" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
      <b-col md="2" />

      <b-col md="2" />
      <b-col md="8">
        <b-card>
          <b-card-text>
            <h4 class="card-title mb-0">
              {{ $t('productfeatures') }}
            </h4>
            <small class="text-muted">{{ $t('productFeaturesDetails') }}</small>
          </b-card-text>

          <b-row>
            <b-col md="6">
              <b-form-group label-for="h-product-name" label-cols-md="3">
                <template v-slot:label>
                  {{ $t('color') }}
                </template>
                <b-form-input id="h-product-name" v-model="color" />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="h-product-name" label-cols-md="3">
                <template v-slot:label>
                  {{ $t('size') }}
                </template>
                <b-form-input id="h-product-name" v-model="size" />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="h-product-name" label-cols-md="3">
                <template v-slot:label>
                  {{ $t('weightKg') }} <span class="text-danger">(KG.)</span>
                </template>
                <b-form-input id="h-product-name" v-model="weight" />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="h-width-name" label-cols-md="3">
                <template v-slot:label>
                  {{ $t('width') }} <span class="text-danger">(cm.)</span>
                </template>
                <b-form-input id="h-width-name" v-model="wide" />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="h-height-name" label-cols-md="3">
                <template v-slot:label>
                  {{ $t('height') }} <span class="text-danger">(cm.)</span>
                </template>
                <b-form-input id="h-height-name" v-model="high" />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label-for="h-long-name" label-cols-md="3">
                <template v-slot:label>
                  {{ $t('length') }} <span class="text-danger">(cm.)</span>
                </template>
                <b-form-input id="h-long-name" v-model="long" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="2" />

      <b-col md="2" />
      <b-col md="8">
        <b-card>
          <b-card-text>
            <h4 class="card-title mb-0">
              {{ $t('salesInfo') }}
            </h4>
          </b-card-text>

          <b-row>
            <b-col md="6">
              <b-form-group label-for="h-product-price" label-cols-md="3">
                <template v-slot:label>
                  <span class="text-danger">*</span>{{ $t('price') }}
                </template>
                <validation-provider #default="{ errors }" name="Product Price" rules="required">
                  <b-form-input id="h-product-price" v-model="price" :state="errors.length > 0 ? false : null"
                    type="text" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6" />
            <b-col md="6">
              <b-form-group label-for="h-stock-id" label-cols-md="3">
                <template v-slot:label>
                  <span class="text-danger">*</span>{{ $t('warehouse') }} <i
                    v-b-popover.hover.right="'จำนวนคลัง หมายถึงจำนวนสินค้าทั้งหมดที่ผู้ขายมีอยู่ในคลังสินค้า รวมถึงสินค้าสำรองไว้สำหรับโปรโมชั่นด้วย'"
                    class="fal fa-question-circle" />
                </template>
                <validation-provider #default="{ errors }" name="stock" rules="required">
                  <b-form-input id="h-stock-id" v-model="quantity" :state="errors.length > 0 ? false : null"
                    type="text" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6" />

          </b-row>
        </b-card>
      </b-col>
      <b-col md="2" />

      <b-col md="2" />
      <b-col md="8">
        <div class="product-selected-fix anan-fix-bottom-card">
          <div class="fix-container" style="z-index: 999">
            <div class="containery">
              <div class="container-left">
                <!---->
              </div>
              <div class="container-right btn-group">
                <button type="button" class="anan-button--xl-large anan-button anan-button--normal"
                  @click="CancelList()" :title="$t('cancel')" :disabled="isDisabled">
                  <span> {{ $t('cancel') }} </span>
                </button>

                <button type="button" class="anan-button--xl-large anan-button anan-button--normal"
                  @click="validationForm(1)">
                  <span> {{ $t('saveAndHide') }} </span>
                </button>

                <button type="button" class="anan-button--xl-large anan-button anan-button--primary anan-button--normal"
                  @click="validationForm(0)">
                  <span> {{ $t('saveAndPublish') }} </span>
                </button>
              </div>
            </div>
          </div>
          <div class="fix-placeholder" style="height: 64px; display: none" />
        </div>
      </b-col>
      <b-col md="2" />
    </b-row>

    <b-modal ref="my-modal" centered title="Catagory">
      <label for="cat">{{ $t('typeName') }} <span class="text-danger">*</span></label>
      <div class="d-flex align-items-center mb-1">
        <b-form-input v-model="cat.name" type="text" size="sm" style="width: 250px;" />
        <button v-if="cat._id" class="btn btn-gradient-primary btn-sm ml-1" @click="Submit()">
          Edit Catagory
        </button>
        <button v-else class="btn btn-gradient-primary btn-sm ml-1" @click="Submit()">
          Add Catagory
        </button>

      </div>

      <b-table responsive="sm" :fields="fields" :items="items">
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(action)="data">
          <i class="fal fa-edit mr-1 cursor-pointer" @click="EditCat(data.item)" />

          <i class="fal fa-trash-alt cursor-pointer text-danger" @click="DeleteCat(data.item)" />
        </template>
      </b-table>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BCardText,
  BCard,
  BFormFile,
  BFormTextarea,
  VBPopover,
  BModal,
  BTable,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BCard,
    BFormFile,
    BFormTextarea,
    vSelect,
    BModal,
    BTable,
  },
  directives: {
    'b-popover': VBPopover,
  },
  data() {
    return {
      selectedFiles: [],
      cat: {
        _id: null,
        name: null,
      },
      emailValue: '',
      name: '',
      required,
      fileName: '',
      files: {},
      option: [{ title: 'Square' }, { title: 'Rectangle' }, { title: 'Rombo' }, { title: 'Romboid' }],
      uploadedImages: [],
      productName: '',
      catagory: '',
      productId: '',
      detail: '',
      price: '',
      stock: '',
      width: '',
      height: '',
      long: '',

      items: [],
    }
  },
  computed: {
    fields() {
      return [
        { key: 'index', label: this.$t('sequence'), thStyle: { width: '5%' } },
        { key: 'name', label: this.$t('category'), thStyle: { width: '80%' } },
        { key: 'action', label: this.$t('action'), thStyle: { width: '15%' } },
      ]
    },
    uploadedImagesWithUrls() {
      return this.uploadedImages.map(image => ({
        ...image,
        url: URL.createObjectURL(image.file),
      }))
    },
  },
  mounted() {
    this.GetCatagory()
  },
  methods: {
    handleFileSelect(event) {
      const selectedFiles = event.target.files
      const promises = Array.from(selectedFiles).map(file => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          // eslint-disable-next-line no-param-reassign
          file.preview = reader.result
          resolve(reader.result)
        }
        reader.onerror = error => {
          reject(error)
        }
        reader.readAsDataURL(file)
      }))

      Promise.all(promises)
        .then(base64Images => {
          this.selectedFiles = Array.from(selectedFiles)
          this.files = base64Images
        })
        .catch(error => {
          console.error('Error reading files:', error)
        })
    },
    GetCatagory() {
      this.$http
        .get('/catagory/list/all')
        .then(response => {
          // console.log(response.data)
          this.items = response.data
        })
        .catch(error => console.log(error))
    },
    Submit() {
      const formData = {
        name: this.cat.name,
        // eslint-disable-next-line no-underscore-dangle
        _id: this.cat._id,
      }
      this.$http
        .post('/catagory/storeAndupdate', formData)
        .then(() => {
          this.GetCatagory()
          // eslint-disable-next-line no-underscore-dangle
          if (this.cat._id) {
            this.Success('แก้ไขชื่อหมวดหมู่สำเร็จ')
          } else {
            this.Success('เพิ่มหมวดหมู่สำเร็จ')
          }
          this.cat.name = null
          // eslint-disable-next-line no-underscore-dangle
          this.cat._id = null
          // this.hideModal()
        })
        .catch(error => {
          this.SwalError(error.response.data.message)
        })
    },
    EditCat(data) {
      this.cat.name = data.name
      // eslint-disable-next-line no-underscore-dangle
      this.cat._id = data._id
    },
    CancelList() {
      this.isDisabled = true;
      this.$swal({
        title: "ยืนยันที่จะยกเลิกรายการสินค้า ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("key-13"),
        cancelButtonText: this.$t("cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) { 
          this.$router.push({ name: 'admin-mall-list' }); // ใช้ชื่อ route
        } else {
          this.isDisabled = false;
        }
      });
    },


    DeleteCat(data) {
      // console.log(data)
      this.$swal({
        title: 'ยืนยันที่จะลบหมวดหมู่ ?',
        text: `ชื่อ ${data.name ? data.name : '-'} หรือไม่ ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          const obj = {
            data,
          }
          this.$http
            .post('/catagory/delete', obj)
            // eslint-disable-next-line no-unused-vars
            .then(response => {
              this.GetCatagory()
              this.Success('ลบหมวดหมู่สำเร็จ')
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
        } else {
          this.$swal('ยกเลิก', 'ยกเลิกคำสั่ง !', 'error')
        }
      })
    },
    validationForm(hide) {
      // eslint-disable-next-line no-unused-vars
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const formData = {
            name: this.name, // ชื่อสินค้า
            // eslint-disable-next-line no-underscore-dangle
            category_id: this.category._id, // หมวดหมู่
            category_name: this.category.name,
            code: this.code, // รหัสสินค้า
            picture: this.files, // ภาพสินค้า
            details: this.details, // รายละเอียดสินค้า
            color: this.color, // สี
            size: this.size, // ขนาด
            weight: this.weight, // น้ำหนัก
            wide: this.wide, // กว้าง
            high: this.high, // สูง
            long: this.long, // ยาว
            price: this.price, // ราคา
            quantity: this.quantity, // คลัง ( จำนวนสินค้า )
            hide, // 0 = แสดง , 1 = ซ่อน
          }
          // console.log(formData)
          this.$http
            .post('/product/store', formData)
            .then(() => {
              // eslint-disable-next-line no-underscore-dangle
              this.Success('เพิ่มสินค้าสำเร็จ')
              this.name = null
              this.category = null
              this.code = null
              this.files = null
              this.details = null
              this.color = null
              this.size = null
              this.weight = null
              this.wide = null
              this.high = null
              this.long = null
              this.price = null
              this.quantity = null
              this.selectedFiles = []
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
        }
      })
      this.$router.push({ name: 'admin-mall-list' }); // ใช้ชื่อ route
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    handleFileUpload() {
      // this.uploadedImages = [] // Clear the existing uploaded images
      // console.log(this.files)
      // const imageFiles = Array.from(this.files) // Convert the FileList object to an array
      // const imagePromises = imageFiles.map(
      //   file => new Promise((resolve, reject) => {
      //     const reader = new FileReader()
      //     reader.onload = () => resolve(reader.result)
      //     reader.onerror = error => reject(error)
      //     reader.readAsDataURL(file)
      //   }),
      // )
      // console.log(this.files)
      // Promise.all(imagePromises)
      //   .then(results => {
      //     this.uploadedImages = imageFiles.map((file, index) => ({
      //       file,
      //       name: file.name,
      //       url: results[index],
      //     }))
      //     console.log(this.uploadedImages)
      //   })
      //   .catch(error => {
      //     console.error('Error reading image files:', error)
      //   })
    },
    deleteImage(index) {
      URL.revokeObjectURL(this.uploadedImages[index].url)
      this.uploadedImages.splice(index, 1)
    },
    beforeDestroy() {
      this.uploadedImages.forEach(image => URL.revokeObjectURL(image.url))
    },
  },
}
</script>
<style scoped>
.image-thumbnails {
  display: flex;
  flex-wrap: wrap;
}

.image-container {
  position: relative;
  width: 100px;
  height: 100px;
  margin-top: 10px;
  margin-right: 10px;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: #fafafabd;
  border-radius: 50%;
}

.delete-button:hover {
  opacity: 0.8;
}

.delete-button i {
  color: red;
}
</style>

<style lang="scss" scoped>
.col-form-label {
  text-align: end !important;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
